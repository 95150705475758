import React from 'react'
import styles from '../expandedResults.module.scss'

const InputField = ({ title, onBlur, type, handler, placeholder, required }) => {

    return (
        <div className={styles.inputField}>
            <p>{title}</p>
            <input onBlur={onBlur} type={type} onChange={handler} placeholder={placeholder} required={required}/>
        </div>
    )
}

export default InputField
