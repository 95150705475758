import React from 'react'
import styles from './progressBar.module.scss'

const ProgressBar = ({ completed }) => {

  return (
    <div className={styles.containerStyles}>
      <div className={styles.fillerStyles} style={{width: `${completed}%`}}>
      </div>
    </div>
  );
};

export default ProgressBar;