import React from 'react'
import styles from './spinner.module.scss'

const Spinner = () => {
    return (
        <div className={styles.spinner}>
            <h3 className={styles.srOnly}>Loading</h3>
            <div className={styles.bounce1}></div>
            <div className={styles.bounce2}></div>
            <div className={styles.bounce3}></div>
        </div>
    )
}

export default Spinner
