import React, { useState, useEffect } from 'react'

import styles from './expandedResults.module.scss'
import axios from 'axios'

import ProgressBar from '../ProgressBar/progressBar'

import BackendChart from './backend/backendChart'
import StorefrontChart from './storefront/storefrontChart'

import storefrontScoreTable from './storefront/storefrontScoreTable'
import StorefrontExpandedScore from './storefront/storefrontExpandedScore'

import OptimizationScore from './backend/scores/optimizationScore'
import TeamScore from './backend/scores/teamScore'
import PIMScore from './backend/scores/pimScore'
import DXScore from './backend/scores/dxScore'
import ExpandedForm from './expandedForm'

import * as htmlToImage from 'html-to-image';
import Watermark from '../Watermark/watermark'
import Overlay from './overlay'

import { GoogleSpreadsheet } from "google-spreadsheet";

const ExpandedResults = () => {

    const maxScore = 112;

    const [isSubmited, setIsSubmited] = useState(false)
    const [isRendering, setIsRendering] = useState(false)

    // 1. INPUTS
    const [mainUrl, setMainUrl] = useState('https://')
    const [categoryUrl, setCategoryUrl] = useState('https://')
    const [productUrl, setProductUrl] = useState('https://')
    const [checkoutUrl, setCheckoutUrl] = useState('https://')

    const handleMainChange = (e) => { setMainUrl(e.target.value) }
    const handleCategoryChange = (e) => { setCategoryUrl(e.target.value) }
    const handleProductChange = (e) => { setProductUrl(e.target.value) }
    const handleCheckoutMainChange = (e) => { setCheckoutUrl(e.target.value) }

    const validateSearch = () => {
        if (mainUrl.indexOf("https://") === -1 || mainUrl.indexOf("http") === -1) {
            setMainUrl("https://" + mainUrl)
        }
        if(categoryUrl.indexOf("https://") === -1 || categoryUrl.indexOf("http") === -1) {
            setCategoryUrl("https://" + categoryUrl)
        }
        if(productUrl.indexOf("https://") === -1 || productUrl.indexOf("http") === -1) {
            setProductUrl("https://" + productUrl)
        }
        if(checkoutUrl.indexOf("https://") === -1 || checkoutUrl.indexOf("http") === -1) {
            setCheckoutUrl("https://" + checkoutUrl)
        }
    };


    // 1. CHECKBOXES
    const [deliveryScore, setDeliveryScore] = useState(0)
    const handleDeliveryCheck = (e) => { setDeliveryScore(e.target.checked ? deliveryScore + 1 : deliveryScore - 1) }
    const [paymentScore, setPaymentScore] = useState(0)
    const handlePaymentCheck = (e) => { setPaymentScore(e.target.checked ? paymentScore + 1 : paymentScore - 1) }

    // 2. RADIOS

    const [teamsTitles, setTeamsTitles] = useState({
        mobile_strategy: '',
        behaviour_analysis: '', 
        mobile_promotion: '', 
        building_community: '', 
        building_architecture: '', 
        platform_implementation: '', 
        mobile_testing: '',
        mobile_maintenance: '', 
        ux_interface: '', 
        mobile_commerce: '',
    })

    const [teamsScore, setTeamsScore] = useState({
        mobile_strategy: 0,
        behaviour_analysis: 0,
        mobile_promotion: 0,
        building_community: 0,
        building_architecture: 0,
        platform_implementation: 0,
        mobile_testing: 0,
        mobile_maintenance: 0,
        ux_interface: 0,
        mobile_commerce: 0,
    })

    const handleTeamsRadio = name => e => {
        setTeamsScore({...teamsScore, [name]: e.target.value})
        setTeamsTitles({ ...teamsTitles, [name]: e.target.getAttribute('data-title')})
    }

    // 3. RADIOS

    const [pimScore, setPimScore] = useState({
        keeping_information: 0, 
        transfer_information: 0, 
        partners_complaining: 0, 
        instant_access: 0,
    })

    const handlePimRadio = name => e => { setPimScore({...pimScore, [name]: e.target.value}); }

    // 4. DX - RADIOS

    const [digitalExperienceTitles, setDigitalExperienceTitles] = useState('')
    const [digitalExperienceScore, setDigitalExperienceScore] = useState(0)
    const handleDigitalExperienceRadio = (e) => { setDigitalExperienceScore(e.target.value); setDigitalExperienceTitles(e.target.getAttribute('data-title')) }

    // 5. RADIOS - TECHNOLOGY

    const [technologyUseScore, setTechnologyUseScore] = useState('')
    const handleTechnologyUse = (e) => { setTechnologyUseScore(e.target.value) } 
    const [databaseUseScore, setDatabaseUseScore] = useState('')
    const handleDatabaseUse = (e) => { setDatabaseUseScore(e.target.value) }
    const [productVariantsScore, setProductVariantsScore] = useState('')
    const handleProductVariants = (e) => { setProductVariantsScore(e.target.value) }
    const [businessSize, setBusinessSize] = useState('')
    const handleBusinessSize = (e) => { setBusinessSize(e.target.value) } 

    const [technologyScore, setTechnologyScore] = useState(0)

    useEffect(() => {
        if(technologyUseScore === "Shopify" | technologyUseScore === "WooCommerce (Wordpress)" | technologyUseScore === "PrestaShop") {
            if(productVariantsScore === "Less than 100" || productVariantsScore === "100 - 10 000") {
                if(businessSize === "National") { setTechnologyScore(3)} 
                else { setTechnologyScore(2) }
            } else { setTechnologyScore(1) }
        } else if(technologyUseScore === "Magento" || technologyUseScore === "Shopware" || technologyUseScore === "CommerceTools") {
            if(productVariantsScore === "100 - 10 000" || productVariantsScore === "10 001 - 1 000 000") {
                if(businessSize === "International (several countries, languages)" || businessSize === "National") {
                    setTechnologyScore(3)
                } else { setTechnologyScore(2) }
            } else { setTechnologyScore(1) }
        } else if(technologyUseScore === "BigCommerce" || technologyUseScore === "SAP Commerce (Hybris)" || technologyUseScore === "Custom eCommerce Platform") {
            if(productVariantsScore === "Over 1 000 000") {
                setTechnologyScore(3)
            } else { setTechnologyScore(1) }
        }
    }, [technologyUseScore, databaseUseScore, productVariantsScore, businessSize])

    // ###

    const [indicator, setIndicator] = useState(0)
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        let interval = setInterval(() => indicator < 100 && loading && setIndicator(indicator + Math.random() * 7), 1500)
        return () => {
            clearInterval(interval)
        }
    }, [indicator, loading])

    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [audits, setAudits] = useState([]);

    const [storefrontMain, setStorefrontMain] = useState({performance: 0, pwa: 0, seo: 0})
    const [storefrontCat, setStorefrontCat] = useState({performance: 0, pwa: 0, seo: 0})
    const [storefrontPro, setStorefrontPro] = useState({performance: 0, pwa: 0, seo: 0})
    const [storefrontCheck, setStorefrontCheck] = useState({performance: 0, pwa: 0})

    const handleFormSubmit = (e) => {
        e.preventDefault()
        setIsSubmited(true)
        setLoading(true)
        axios
        .get(`https://pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed?category=PWA&category=PERFORMANCE&category=SEO&strategy=MOBILE&url=${mainUrl}&key=AIzaSyCjj77P6ztAkz83iT2ahw4huFVOO9C1PlE`)
        .then((result) => {
            setData(result.data.lighthouseResult);
            setAudits(result.data.lighthouseResult.audits);
            setStorefrontMain({
                performance: result.data.lighthouseResult.categories.performance['score'],
                pwa: result.data.lighthouseResult.categories.pwa['score'],
                seo: result.data.lighthouseResult.categories.seo['score']
            });
                  const doc = new GoogleSpreadsheet('1RSy-vdbQXkJMExSsgDvHumUlYsPXNBqmZuotgsSkr4I');
                const appendSpreadsheet = async (row) => {
                    try {
                    await doc.useServiceAccountAuth({
                        client_email: 'mci-121@mindex-tool-1601648592701.iam.gserviceaccount.com',
                        private_key: "-----BEGIN PRIVATE KEY-----MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCm7fItB6l6TBtD\n8zUsv0mfx4+sKvHkClgOMjmZqDTQhdpr42rxndoT6IhKBxJkAjnoMrx4RibrJrN1\nLtSZy6TpNrf8mmFlltKoupFNaH78q6VPgk1T+JC/8HQRtdyXXDjaBdYyTsP3i0EP\n+nQoKWBJKG0oS53XgrbdMSa5v3PgcfbeeI7jdimPN4aVff3vi8sZyrUdEcD0ayYk\nGOxC91WmZA1Y0X6TtmpIR7H0+7eY1RhdZKV2A+LIMm/8aKDqXCeGf7+N2LwMcBvi\ncFx31aF4BAdaG9RT5NEW9Kf3M5N2wmZ7PMF/ndxu0eIa44ocJzB+C4z2K+5+my+/\n+86gn4qvAgMBAAECggEAAqC5OqQ+Y/2M4FSobGiGfWoLcUUrzLzApETgmgYHPZYK\nKA4tX0XkkeTkdA1EI+0NqTzgxSA/b6w7/IcJH+jVGBMThXndm5JDVD3jBOtmqoQ5\nOV7krQzO1Z4THRMHh4CdFIBU3fe4sRydUeXXwB8GoGUgXxdAarRoshSQnfZq1OEN\na2rFhE6VN43L6Wz13nU9LHABnRWoncoXPGSuoBPRcWQwfWk+Rg8ndDUqRpN/dZ7C\nchblxvWnvVwyXq43d9HzKp8NrATbtLF/GW8xk2RWjJnnwCNuDywMXQuJMdJXBNIC\n4SKgg3cgQGTCe6h7csT36o3Sv08FerB5wgs2R6SDQQKBgQDcJ5mPmaL2lWDEHywR\n9ntpE/9qVyQGs6fBz3NOfgqpS99adafV/lCEFn8VYIhvJfV8mlh9EkdG+j3JRDTf\nNpmbl/tk5Ddxccp3XlaLQ634CixV6MYnvUIc+O3iFEp/Ir2ae+DyCaw+HxO3xhOL\nT1nE/A9pXJlmv1+b0JZtF7xb2QKBgQDCG9WG9gt46pWwj3OdP4bkeEEzhXDunO67\nqz9mExxlbh3scIOGGA8/romxt2+5UkGMbxyHmEDdBbTj2UTIrdWoChATz7YgdLVY\nghTLclzuI79AF0r5cAOhSwV0KTDx37Y7OLSATS+vOkRI643eiYLsbYy6QxZrYOoA\nfWT+yyctxwKBgAU4YynGiNiz/hFoc9EROSfqs3nACzildergkmlgD7yn8enhNFOc\nrigTqHvHSDTqf4Gu3z5H+qyyLljhZyeL0eSgm/NufeoQuq5N7FttBWkGRLrcxCYZ\nFMI/HNTpikKtfjV1joXuuMjYT81hwwDgps5G886ZxYFsNEYrkgWQNIUxAoGAFNkm\newNsQgV4JGmT8lzDYUClSCJZ+dbzMUb4XT9KOn60ShPfnIRzBtT5RxQ7tLzn+2xn\ntQyeUt7tCdfpMmCxZQYHqgkSN7QxUhtOnndfEdjXDswxqk7kpOtI36wY7LGpgfza\nLjmcGIb6lJZwO2mrnTc6+TqBavES0RCmy1MIlzsCgYEAoXhFgUiswRVDZPavi2Gx\nOv/tZI7ZwEoPkVnSIOfqY9Hg4kA8E+1N2/ADoA7ONGNZRUO0mAfCwNVrJh1bpxqx\ngBWr00ss57JM0La24a8TnUGHRTeSIYm2F5c5lZ3pjXjOzHDvSUo3zRrIFTWM26wU\nYGD1yhV9VY+lfxmUUibcf6M=\n-----END PRIVATE KEY-----",
                    });
                    // loads document properties and worksheets
                    await doc.loadInfo();

                    const sheet = doc.sheetsById["0"];
                    // eslint-disable-next-line no-unused-vars
                    const result = await sheet.addRow(row);
                    console.log(result)
                    } catch (e) {
                    console.error('Error: ', e);
                    }
                };
                const dt = new Date();
                appendSpreadsheet({ Link: mainUrl, Data: dt});
        })
        axios
        .get(`https://pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed?category=PWA&category=PERFORMANCE&category=SEO&strategy=MOBILE&url=${categoryUrl}&key=AIzaSyCjj77P6ztAkz83iT2ahw4huFVOO9C1PlE`)
        .then((result) => {
            setStorefrontCat({
                performance: result.data.lighthouseResult.categories.performance['score'],
                pwa: result.data.lighthouseResult.categories.pwa['score'],
                seo: result.data.lighthouseResult.categories.seo['score'],
            })
        })
        axios
        .get(`https://pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed?category=PWA&category=PERFORMANCE&strategy=MOBILE&url=${productUrl}&key=AIzaSyCjj77P6ztAkz83iT2ahw4huFVOO9C1PlE`)
        .then((result) => {
            setStorefrontCheck({
                performance: result.data.lighthouseResult.categories.performance['score'],
                pwa: result.data.lighthouseResult.categories.pwa['score'],
            })
        })
        axios
        .get(`https://pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed?category=PWA&category=PERFORMANCE&category=SEO&strategy=MOBILE&url=${productUrl}&key=AIzaSyCjj77P6ztAkz83iT2ahw4huFVOO9C1PlE`)
        .then((result) => {
            setStorefrontPro({
                performance: result.data.lighthouseResult.categories.performance['score'],
                pwa: result.data.lighthouseResult.categories.pwa['score'],
                seo: result.data.lighthouseResult.categories.seo['score'],
            })
        })
        .then(() => {
            setIndicator(100)
            countAllScores()
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
        .catch(function (error) {
            if (error) {
            setLoading(false);
            setError(true);
            }
        });
    }

    // ### all scores

    const [resultsPerOne, setResultsPerOne] = useState({team: 0, pim: 0, digital_experience: 0, analytics: 0, technology: 0,})
    const [resultsTotalScore, setResultsTotalScore] = useState('')
    const [backendResultsScore, setBackendResultsScore] = useState(0)
    const [storefrontResultsScore, setStorefrontResultsScore] = useState(0)
    const [allResultsScore, setAllResultsScore] = useState(0)

    useEffect(() => {
        setStorefrontResultsScore(
            (+storefrontMain.performance * 100) + (+storefrontMain.pwa * 100) + (+storefrontMain.seo * 100)
            + (+storefrontCat.performance * 100) + (+storefrontCat.pwa * 100) + (+storefrontCat.seo * 100)
            + (+storefrontPro.performance * 100) + (+storefrontPro.pwa * 100) + (+storefrontPro.seo * 100)
            + (+storefrontCheck.performance * 100) + (+storefrontCheck.pwa * 100)
        )}, [storefrontMain, storefrontCat, storefrontPro, storefrontCheck])

    const [allScores, setAllScores] = useState([])

    const countAllScores = () => {
        
        const team = +teamsScore.mobile_strategy + +teamsScore.behaviour_analysis + +teamsScore.mobile_promotion + +teamsScore.building_community + +teamsScore.building_architecture + +teamsScore.platform_implementation + +teamsScore.mobile_testing + +teamsScore.mobile_maintenance + +teamsScore.ux_interface + +teamsScore.mobile_commerce
        const pim = +pimScore.keeping_information + +pimScore.transfer_information + +pimScore.partners_complaining + +pimScore.instant_access
        const analytics = +deliveryScore + +paymentScore
        const technology = (+technologyScore * 10)
        
        const countBackend = Math.floor(((+team + +pim + +digitalExperienceScore + +analytics + +technology) / maxScore) * 100)
        
        setResultsPerOne({team: team, pim: pim, digital_experience: digitalExperienceScore, technology: technology, analytics: analytics})
        
        setAllScores(prevState => ({ ...prevState, 
            delivery_methods: deliveryScore, payment_methods: paymentScore, 
            
            mobile_strategy: teamsScore.mobile_strategy, 
            behaviour_analysis: teamsScore.behaviour_analysis, mobile_promotion: teamsScore.mobile_promotion, 
            building_community: teamsScore.building_community, building_architecture: teamsScore.building_architecture, 
            platform_implementation: teamsScore.platform_implementation, mobile_testing: teamsScore.mobile_testing, 
            mobile_maintenance: teamsScore.mobile_maintenance, ux_interface: teamsScore.ux_interface, mobile_commerce: teamsScore.mobile_commerce, 

            keeping_information: pimScore.keeping_information, transfer_information: pimScore.transfer_information, 
            digital_experience: digitalExperienceScore, technology: technologyScore, }))
        setBackendResultsScore(countBackend)
    }
    
    useEffect(() => {
        setAllResultsScore(Math.round(((storefrontResultsScore / 11) + (backendResultsScore * 3)) / 4))
        allResultsScore >= 90 ? setResultsTotalScore('Excellent') : allResultsScore >= 50 ? setResultsTotalScore('Average') : setResultsTotalScore('Poor')
    }, [storefrontResultsScore, backendResultsScore, allResultsScore])

    const handleSaveImg = () => {
        setIsRendering(true)
        document.body.className = 'hideOverflow'
        setTimeout(function() {
            htmlToImage.toJpeg(document.getElementById('toImg'), { quality: 0.95, backgroundColor: "#fff", width: 1920, pixelRatio: 1 })
            .then(function (dataUrl) {
                setIsRendering(false)
                document.body.className = 'hideOverflow'
                var link = document.createElement('a');
                link.download = 'MCI Raport.jpeg';
                link.href = dataUrl;
                link.click();
            });
        }, 1000)
    }

    return (
        <div>
            <div className={styles.expandedLanding}></div>
            {error ? <h2 className={`${styles.subTitle} ${styles.textCenter}`}>Provided URL is incorrect, please try again</h2> : isSubmited ? 
            loading ? 
            <div>
                <h2 className={`${styles.title} ${styles.textCenter}`}>Gathering data</h2>
                <p className={styles.textCenter}>This may take up to several minutes.</p>
                <p className={styles.textCenter}>In order not to lose the survey results, please press the "download report" button as soon as the results page appears.</p>
                <ProgressBar completed={indicator} />
            </div>
             :
            audits.length !== 0 && (
                <div>
                    <div className={`${styles.results} ${isRendering ? styles.render : ''}`} id="toImg">
                        {isRendering && <Watermark/>}
                        <div className={styles.resultsHeading}>
                            <a rel="noopener noreferrer" target="_blank" href={data['finalUrl'].split("?key")[0]}>{data['finalUrl'].split("?key")[0]}</a>
                            <h2 className={styles.title}>Your Expanded MCI v1.0 is:</h2>
                            <h2 className={styles.subTitle}>Mobile eCommerce Index</h2>
                            <span className={`${styles[resultsTotalScore]} ${styles.scoring}`}>{resultsTotalScore}</span>
                            <h2 className={styles.subTitle}>{allResultsScore} / 100</h2>
                            <h3>Conclusions:</h3>
                            <p>It seams that your Mobile eCommerce ecosystem has a lot of critical issues you sohuld take care ASAP</p>
                            <h3 className={styles.saveResults}>Save your results</h3>
                            <p className={styles.saveResults}>Do not loose your results.</p>
                            <button className={`${styles.saveResults} ${styles.btnDecorated} ${styles.btnDownload}`} onClick={handleSaveImg}>Download report</button>
                        </div>
                        <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.sectionDivider}`}>See where you can improve your Mobile eCommerce Ecosystem</h2>
                        <div className={styles.charts}>
                            <div className={styles.chart}>
                                <h2 className={styles.textCenter}>mCommerce Storefront</h2>
                                <StorefrontChart
                                    mainPwa={storefrontMain.pwa}
                                    mainPerf={storefrontMain.performance}
                                    mainSeo={storefrontMain.seo}
                                    catPwa={storefrontCat.pwa}
                                    catPerf={storefrontCat.performance}
                                    catSeo={storefrontCat.seo}
                                    proPwa={storefrontPro.pwa}
                                    proPerf={storefrontPro.performance}
                                    proSeo={storefrontPro.seo}
                                    chePwa={storefrontCheck.pwa}
                                    chePerf={storefrontCheck.performance}
                                />
                            </div>
                            <div className={styles.chart}>
                                <h2 className={styles.textCenter}>Business Backend</h2>
                                <BackendChart 
                                    team={(resultsPerOne.team)/10} 
                                    pim={(resultsPerOne.pim)/40} 
                                    digital_experience={(resultsPerOne.digital_experience)/10}
                                    analytics={(resultsPerOne.analytics)/15}
                                    technology={(resultsPerOne.technology)/30} />
                            </div>
                        </div>
                        <div className={`${styles.storefrontResults}`}>
                            <div className={styles.storefrontResult} id="homepage">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>mCommerce Storefront - Homepage
                                </h2>
                                <div className={styles.storefrontResultWrapper}>
                                    <StorefrontExpandedScore
                                        render={isRendering}
                                        data={storefrontScoreTable} 
                                        type="main" 
                                        performance={storefrontMain.performance}
                                        pwa={storefrontMain.pwa} 
                                        seo={storefrontMain.seo} />
                                </div>
                            </div>
                            <div className={styles.storefrontResult} id="categorypage">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>mCommerce Storefront - Category page</h2>
                                <div className={styles.storefrontResultWrapper}>
                                    <StorefrontExpandedScore
                                        render={isRendering}
                                        data={storefrontScoreTable} 
                                        type="category" 
                                        performance={storefrontCat.performance} 
                                        pwa={storefrontCat.pwa} 
                                        seo={storefrontCat.seo} />
                                </div>
                            </div>
                            <div className={styles.storefrontResult} id="productpage">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>mCommerce Storefront - Product page</h2>
                                <div className={styles.storefrontResultWrapper}>
                                    <StorefrontExpandedScore
                                        render={isRendering}
                                        data={storefrontScoreTable} 
                                        type="product" 
                                        performance={storefrontPro.performance}
                                        pwa={storefrontPro.pwa}
                                        seo={storefrontPro.seo} />
                                </div>
                            </div>
                            <div className={styles.storefrontResult} id="checkoutpage">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>mCommerce Storefront - Checkout page</h2>
                                <div className={styles.storefrontResultWrapper}>
                                    <StorefrontExpandedScore
                                        isCheckout
                                        render={isRendering}
                                        data={storefrontScoreTable} 
                                        type="checkout" 
                                        performance={storefrontCheck.performance}
                                        pwa={storefrontCheck.pwa} />
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.storefrontWrapper}`}>
                            <div className={styles.deliveryPayment} id="deliverypayment">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>Delivery/Payment methods</h2>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Delivery methods</h2>
                                        <OptimizationScore render={isRendering} data={allScores} type="delivery_methods" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Payment methods</h2>
                                        <OptimizationScore render={isRendering} data={allScores} type="payment_methods" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.teamCompetencies} id="team">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>Team and competencies</h2>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Developing Mobile eCommerce Strategy</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.mobile_strategy} data={allScores} type="mobile_strategy" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Mobile Customer buying behaviour analysis</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.behaviour_analysis} data={allScores} type="behaviour_analysis" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Planning and executing mobile promotion</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.mobile_promotion} data={allScores} type="mobile_promotion" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Creating mobile content marketing that is focused on building community around your e-shop and/or brand</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.building_community} data={allScores} type="building_community" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Analysing and building architecture of Mobile eCommerce platform</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.building_architecture} data={allScores} type="building_architecture" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Implementing of Mobile eCommerce platform</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.platform_implementation} data={allScores} type="platform_implementation" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Testing of Mobile eCommerce platform</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.mobile_testing} data={allScores} type="mobile_testing" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Maintenance of Mobile eCommerce platform</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.mobile_maintenance} data={allScores} type="mobile_maintenance" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Designing of UX mobile interface</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.ux_interface} data={allScores} type="ux_interface" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Project management not only in eCommerce but also mobile eCommerce</h2>
                                        <TeamScore render={isRendering} title={teamsTitles.mobile_commerce} data={allScores} type="mobile_commerce" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.pim} id="pim">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>PIM</h2>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Where do you keep information about products (descriptions, parameters, prices etc.)</h2>
                                        <PIMScore render={isRendering} data={allScores} type="keeping_information" />
                                    </div>
                                </div>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>What tools do you use to transfer product information across different departments</h2>
                                        <PIMScore render={isRendering} data={allScores} type="transfer_information" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.dx} id="dx">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>Digital Experience</h2>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Where do you keep information about Digital Experience (DX) of your customers</h2>
                                        <DXScore render={isRendering} title={digitalExperienceTitles} data={allScores} type="digital_experience" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.technology} id="technology">
                                <h2 className={`${styles.subTitle} ${styles.textCenter} ${styles.titleDivider}`}>Technology Matching</h2>
                                <div className={styles.backendResult}>
                                    <div className={styles.container}>
                                        <h2 className={`${styles.subTitle}`}>Technology matching</h2>
                                        <PIMScore render={isRendering} data={allScores} type="technology" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className={`${styles.saveResults} ${styles.btnDecorated} ${styles.btnDownload}`} onClick={handleSaveImg}>Download report</button>
                        {isRendering && <Watermark/>}
                    </div>
                    {isRendering && <Overlay />}
                </div>
            ) :
                <ExpandedForm
                    validateSearch={validateSearch}
                    handleMainChange={handleMainChange}
                    handleCategoryChange={handleCategoryChange}
                    handleProductChange={handleProductChange}
                    handleFormSubmit={handleFormSubmit}
                    handleCheckoutMainChange={handleCheckoutMainChange}
                    handleDeliveryCheck={handleDeliveryCheck}
                    handlePaymentCheck={handlePaymentCheck}

                    handleTeamsRadio={handleTeamsRadio}
                    handlePimRadio={handlePimRadio}

                    handleDigitalExperienceRadio={handleDigitalExperienceRadio}
                    handleTechnologyUse={handleTechnologyUse}
                    handleDatabaseUse={handleDatabaseUse}
                    handleProductVariants={handleProductVariants}
                    handleBusinessSize={handleBusinessSize}
                 />}
        </div>
    )
}

export default ExpandedResults
