import React from 'react'
import styles from './score.module.scss'
import Circle from 'react-circle'

const HomeScoreBox = ({ render, coloredBackground, totalScore, score, children }) => {

    return (
        <div className={`${styles.scoreBox} ${render ? styles.render : ''} ${coloredBackground ? styles.colored : ''}`}>
            <div className={styles.container}>
                <div className={styles.scoreBoxContent}>
                    {children}
                </div>
                <div className={`${styles.scoreBoxIndicator} ${styles[score]}`}>
                    <Circle
                        animate={true}
                        progress={totalScore*100}
                        responsive={true}
                        size={450}
                        progressColor={score === 'Excellent' ? '#06BD18' : score === 'Average' ? '#FFC700' : '#E31212'}
                        showPercentage={false}
                        showPercentageSymbol={false}
                     />
                 <p className={`${styles.totalScoreBasic} ${styles.textCenter} ${styles.gaugeText}`}>{score}</p>
                </div>
            </div>
        </div>
    )
}

export default HomeScoreBox
