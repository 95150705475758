import React from 'react'
import { form } from '../formData'

import styles from '../expandedResults.module.scss'

const Technology = ({ handler, name }) => {
    return (
        <div className={styles.radioField}>
            <p>{form[4].radio[name].title}</p>
                <div onChange={handler}>
                    {form[4].radio[name].options.map((post) => (
                        <label key={post} htmlFor={post}>
                            <span>{post}</span>
                            <input value={post} name={name} id={post} type="radio" required/>
                        </label>
                    ))}
                </div>
        </div>
    )
}

export default Technology
