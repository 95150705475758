import React from 'react'
import { form } from '../formData'

import styles from '../expandedResults.module.scss'

const Radio = ({ formIndex, handler, name }) => {
    return (
        <div className={styles.radioField} onChange={handler}>
            <p>{form[1].radio[name].title}</p>
            <p>{form[1].question}</p>
                <div>
                    <label htmlFor={`${name}-CI`}>
                        <span>{form[formIndex].options[0]}</span>
                        <input value={1} data-title="Covered - Inhouse" name={name} id={`${name}-CI`} type="radio"/>
                    </label>
                    <label htmlFor={`${name}-CO`}>
                        <span>{form[formIndex].options[1]}</span>
                        <input value={1} data-title="Covered - outsourcing" name={name} id={`${name}-CO`} type="radio"/>
                    </label>
                    <label htmlFor={`${name}-NC`}>
                        <span>{form[formIndex].options[2]}</span>
                        <input value={0} data-title="Not covered" name={name} id={`${name}-NC`} type="radio" required/>
                    </label>
                </div>
        </div>
    )
}

export default Radio
