import React from 'react'
import { form } from '../formData'

import styles from '../expandedResults.module.scss'

const RadioPIM = ({ formIndex, handler, name }) => {

    const random = (Math.random() * 100).toFixed(0)

    return (
        <div className={styles.radioField} onChange={handler}>
            <p>{form[formIndex].radio[name].title}</p>
                {form[formIndex].radio[name].options.map((post, index) => (
                    <label key={post.name} htmlFor={`${post.name}-${index}-${random}`}>
                        <span>{post.name}</span>
                        <input data-title={post.name} value={post.score} name={name} id={`${post.name}-${index}-${random}`} type="radio" required/>
                    </label>
                ))}
        </div>
    )
}

export default RadioPIM
