import React from 'react'

import RadarChart from 'react-svg-radar-chart'
import 'react-svg-radar-chart/build/css/index.css'


const Chart = ({ mainPwa, mainPerf, mainSeo, catPwa, catPerf, catSeo, proPwa, proPerf, proSeo, chePwa, chePerf }) => {

    const data = [
          {
            data: {
              mainPwa: mainPwa,
              mainPerf: mainPerf,
              mainSeo: mainSeo,
              catPwa: catPwa,
              catPerf: catPerf,
              catSeo: catSeo,
              proPwa: proPwa,
              proPerf: proPerf,
              proSeo: proSeo,
              chePwa: chePwa,
              chePerf: chePerf,
            },
            meta: { color: '#27ae60' }
          },
        ];
     
    const captions = {
          mainPwa: 'Main: Mobile-friendliness',
          mainPerf: 'Main: Performance',
          mainSeo: 'Main: SEO',
          catPwa: 'Category: Mobile-friendliness',
          catPerf: 'Category: Performance',
          catSeo: 'Category: SEO',
          proPwa: 'Product: Mobile-friendliness',
          proPerf: 'Product: Performance',
          proSeo: 'Product: SEO',
          chePwa: 'Checkout: Mobile-friendliness',
          chePerf: 'Checkout: Performance',
        };

    return (
        <div>
            <RadarChart
                captions={captions}
                data={data}
                size={450}
            />
        </div>
    )
}

export default Chart
