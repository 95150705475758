import React from 'react'
import styles from './footer.module.scss'

import Logo from '../../assets/epoint.png'

const Footer = () => {
    return (
      <footer className={styles.footer}>
          <div className={styles.wrapper}> 
              <a href="https://e-point.com/"><img src={Logo} alt="e-point homepage" /></a>
              <p>© 2021 e-point mobile sp. z o.o. –  All rights reserved</p>
          </div>
      </footer>
    );
}

export default Footer
