import React, { useEffect, useState } from 'react'
import HomeScoreBox from './homeScoreBox';
import styles from './score.module.scss'

const Score = ({ performance, data, pwa, seo }) => {

    const finalUrl = data['finalUrl'].split("?key")[0]
    
    const [performanceScore, setPerformanceScore] = useState('')
    
    const [pwaScore, setPwaScore] = useState('')
    
    const [seoScore, setSeoScore] = useState('')

    const countScore = (seo + performance*2 + pwa*2) / 5
    const totalScore = countScore >= 0.85 ? 'Excellent' : countScore >= 0.41 ? 'Average' : 'Poor' 
    
    const [pwaTextHead, setPwaTextHead] = useState('')
    const [pwaTextBody, setPwaTextBody] = useState('')
    const [pwaTextRec, setPwaTextRec] = useState('')
    const [pwaTextList, setPwaTextList] = useState([])
    
    const [performanceTextHead, setPerformanceTextHead] = useState('')
    const [performanceTextBody, setPerformanceTextBody] = useState('')
    const [performanceTextRec, setPerformanceTextRec] = useState('')
    const [performanceTextList, setPerformanceTextList] = useState([])
    
    const [seoTextHead, setSeoTextHead] = useState('')
    const [seoTextBody, setSeoTextBody] = useState('')
    const [seoTextRec, setSeoTextRec] = useState('')
    const [seoTextList, setSeoTextList] = useState([])

    useEffect(() => {
        if(pwa >= 0.9) {
            setPwaScore('Excellent')
            setPwaTextHead('Excellent! Your e-commerce is mobile-friendly')
            setPwaTextBody('It seems that you are using the newest technology and you have implemented PWA standard on your webshop. Mobile customers have easy access to your products and can easily buy them using a smartphone. Nevertheless, there is always a space for improvement for such an innovative company as yours.')
            setPwaTextRec('First of all - Good job! To find potential areas of improvement we recommend: ')
            setPwaTextList([
                "Running mobile ecosystem assessment, to find what areas you can be improved. You can show the results to your team and think about potential solutions.",
                "Visit our site and blog where you will find a lot of ideas on how to improve mobile and e-commerce ecosystem.",
                "You can also contact our mobile and e-commerce experts."
            ])
        } else if(pwa >= 0.5) {
            setPwaScore('Average')
            setPwaTextHead('Yor e-commerce could be much more mobile-friendly')
            setPwaTextBody('It seems that you tried to make your e-commerce a mobile-friendly, but you are using old technology. Even if you tried to Implement PWA standard, it seems that it was not implemented properly. There is a high probability, that you are losing money every day because mobile customers do not receive a great mobile experience and probably they are facing some issues when trying to buy your products using smartphone.')
            setPwaTextRec('It is advised to take action as soon as possible. We recommend:')
            setPwaTextList([
                "Running mobile ecosystem assessment, to find what areas you can be improved. You can show the results to your team and think about potential solutions.",
                "Visit our site and blog where you will find a lot of insights on how to improve mobile and e-commerce ecosystem.",
                "You can also contact our mobile and e-commerce experts."
            ])
        } else {
            setPwaScore('Poor')
            setPwaTextHead('Warning! Your e-commerce is not mobile-friendly.')
            setPwaTextBody('It seems that your e-commerce was build using old and not mobile-friendly technology. There is a high probability, that you are losing money every day because mobile customers do not receive a great mobile experience and probably they are unable to buy your products when using a smartphone.')
            setPwaTextRec('It is advised to take immediate action. We recommend:')
            setPwaTextList([
                "Running mobile ecosystem assessment, to find what areas you can be improved. You can show the results to your team and think about potential solutions.",
                "Visit our site and blog where you will find a lot of insights on how to improve mobile and e-commerce ecosystem.",
                "You can also contact our mobile and e-commerce experts."
            ])
        }
    }, [pwa])
    
    useEffect(() => {
        if(performance >= 0.9) {
            setPerformanceScore('Excellent')
            setPerformanceTextHead('Excellent! Your e-commerce is super fast!')
            setPerformanceTextBody('It seems that you know what you are doing. Performance of your e-commerce is great!  Mobile customers can enter your site very fast and start to browse or buy products. Nevertheless, there is always a space for improvement of webshop performance.')
            setPerformanceTextRec('First of all - Good job! To find potential areas of improvement we recommend: ')
            setPerformanceTextList([
                "Running mobile ecosystem assessment, to find out where you can improve your e-shop to be even faster.  You can show the results to your team and thing about potential solutions.",
                "Visit our site and blog and find out how to improve performance.",
                "You can also contact our mobile and e-commerce experts and trust our experience."
            ])
        } else if(performance >= 0.5) {
            setPerformanceScore('Average')
            setPerformanceTextHead('Yor e-commerce could be much faster.')
            setPerformanceTextBody('Loading time and performance of your e-commerce equals money. It seems that your site can load much faster on smartphones. There are a lot of areas to improve it.')
            setPerformanceTextRec('It is advised to take action as soon as possible. We recommend:')
            setPerformanceTextList([
                "Running mobile ecosystem assessment, to find what exactly should be fixed to improve your performance to an excellent level.  You can show the results to your team and discuss how to fix those issues. ",
                "Visit our site and blog and find out how to improve performance.",
                "You can also contact our mobile and e-commerce experts and trust our experience."
            ])
        } else {
            setPerformanceScore('Poor')
            setPerformanceTextHead('Warning! Your e-commerce is extremely slow!')
            setPerformanceTextBody('It seems that your e-commerce is extremely slow on mobile devices. It means that you are losing money because mobile customers are not able to enter your shop at all or it takes ages to enter it when using a smartphone.')
            setPerformanceTextRec('It is advised to take immediate action. We recommend:')
            setPerformanceTextList([
                "Running mobile ecosystem assessment, to find what problems you need to fix to improve performance of your e-commerce. You can show the results to your team and discuss how to fix those issues. ",
                "Visit our site and blog where you will find a lot of insights about improving performance.",
                "You can also contact our mobile and e-commerce experts and trust our experience."
            ])
        }
    }, [performance])
    
    useEffect(() => {
        if(seo >= 0.9) {
            setSeoScore('Excellent')
            setSeoTextHead('Excellent! Google loves your e-commerce!')
            setSeoTextBody("It seems that your team spends a lot of time to improve the SEO of your e-commerce. That's great. Nevertheless, there is always space for improvement.")
            setSeoTextRec('First of all - Good job! To find potential areas of improvement we recommend: ')
            setSeoTextList([
                "Running mobile ecosystem assessment, to find out where you can improve your SEO. You can show the results to your team and think about potential solutions.",
                "Visit our site and blog and find out how to improve SEO.",
                "You can also contact our mobile and e-commerce experts and trust our experience."
            ])
        } else if(seo >= 0.5) {
            setSeoScore('Average')
            setSeoTextHead('Your e-commerce could be optimized for SEO much better.')
            setSeoTextBody('It seems that you are aware of the importance of SEO in your e-commerce but there are a log of areas you could improve to cut advertising costs and earn on organic search.')
            setSeoTextRec('It is advised to take action as soon as possible. We recommend:')
            setSeoTextList([
                "Running mobile ecosystem assessment, to find what exactly should be fixed to improve your SEO.  You can show the results to your team and discuss how to fix those issues. ",
                "Visit our site and blog and find out how to improve SEO",
                "You can also contact our mobile and e-commerce experts and trust our experience."
            ])
        } else {
            setSeoScore('Poor')
            setSeoTextHead('Warning! Your e-commerce is not SEO-optimized')
            setSeoTextBody('It seems that your e-commerce is totally not SEO-optimized. It means that there is no way your products can appear in organic search. To sell you need to pay for ads, over and over again. Building a strong SEO position means spending less on ads.')
            setSeoTextRec('It is advised to take immediate action. We recommend:')
            setSeoTextList([
                "Running mobile ecosystem assessment, to find what exactly causes SEO problems. You can show the results to your team and discuss how to fix those issues. ",
                "Visit our site and blog, where you will find a lot of insights about improving webshop SEO",
                "You can also contact our mobile and e-commerce experts and trust our experience."
            ])
        }

    }, [seo])

    return (
        <div className={styles.score}>
            <div className={styles.scoreDetails}>
                <h4 className={`${styles.textCenter} ${styles.currentUrl}`}><a rel="noopener noreferrer" target="_blank" href={finalUrl}>{ finalUrl }</a></h4>
                <h3 className={`${styles.subTitle} ${styles.textContainer} ${styles.textCenter}`}>Your <b>basic mobile commerce score</b> is:</h3>
                <h2 className={`${styles.title} ${styles.textContainer} ${styles.textCenter} ${styles[totalScore]}`}>
                    {totalScore}
                </h2>
                <p className={styles.textCenter}>Top issues and recommendations:</p>
            </div>
            <HomeScoreBox coloredBackground totalScore={pwa} score={pwaScore}>
                    <h3 className={styles.subTitle}>{pwaTextHead}</h3>
                    <p>
                        {pwaTextBody}
                    </p>
                    <p>
                        {pwaTextRec}
                    </p>
                    <ul>
                        <li>{pwaTextList[0]}</li>
                        <li>{pwaTextList[1]}</li>
                        <li>{pwaTextList[2]}</li>
                    </ul>
                    <div className={styles.scoreLinks}>
                        {/* <Link to='/expanded'
                            className={styles.btnDecorated}
                        >Run Expanded MCI</Link> */}
                        <a target="_blank" rel="noopener noreferrer" href='https://www.e-point.com/blog' className={styles.btnDecoratedBorder}>
                            Read our blog
                        </a>
                    </div>
            </HomeScoreBox>
            <HomeScoreBox totalScore={performance} score={performanceScore}>
                    <h3 className={styles.subTitle}>{performanceTextHead}</h3>
                    <p>
                        {performanceTextBody}
                    </p>
                    <p>
                        {performanceTextRec}
                    </p>
                    <ul className={styles.scoreBoxList}>
                        <li>{performanceTextList[0]}</li>
                        <li>{performanceTextList[1]}</li>
                        <li>{performanceTextList[2]}</li>
                    </ul>
                    <div className={styles.scoreLinks}>
                        {/* <Link to='/expanded'
                            className={styles.btnDecorated}
                        >Run Expanded MCI</Link> */}
                        <a target="_blank" rel="noopener noreferrer" href='https://www.e-point.com/blog' className={styles.btnDecoratedBorder}>
                            Read our blog
                        </a>
                    </div>
            </HomeScoreBox>
            <HomeScoreBox coloredBackground totalScore={seo} score={seoScore}>
                    <h3 className={styles.subTitle}>{seoTextHead}</h3>
                    <p>
                        {seoTextBody}
                    </p>
                    <p>{seoTextRec}</p>
                    <ul className={styles.scoreBoxList}>
                        <li>{seoTextList[0]}</li>
                        <li>{seoTextList[1]}</li>
                        <li>{seoTextList[2]}</li>
                    </ul>
                    <div className={styles.scoreLinks}>
                        {/* <Link to='/expanded'
                            className={styles.btnDecorated}
                        >Run Expanded MCI</Link> */}
                        <a target="_blank" rel="noopener noreferrer" href='https://www.e-point.com/blog' className={styles.btnDecoratedBorder}>
                            Read our blog
                        </a>
                    </div>
            </HomeScoreBox>
            <div className={`${styles.expandedInvitation}`}>
                <div className={styles.container}>
                    <h2 className={`${styles.subTitle} ${styles.textCenter}`}>Check mobile ecosystem assessment</h2>
                        <div className={styles.expandedBody}>
                        <span>Expanded mobile analysis of your e-commerce:</span>
                        <ul>
                            <li><span className={styles.important}>Home page</span> – is it performing well and attracting new customers?</li>
                            <li><span className={styles.important}>Category page</span> – is it easy to find what customers are looking for?</li>
                            <li><span className={styles.important}>Product page</span> – is it easy to choose the right product for the customer?</li>
                            <li><span className={styles.important}>Checkout page</span> – is it smooth and easy to finalize transactions?</li>
                            <li><span className={styles.important}>Payment methods</span> – do you have the most suitable payment methods mix?</li>
                            <li><span className={styles.important}>Delivery Methods</span> – do you have delivery methods that customers expect?</li>
                        </ul>
                        <span>Also:</span>
                        <ul>
                            <li><span className={styles.important}>TEAM</span> - Your team and mobile/e-commerce skills – do you have a proper mix of competencies to run successful mobile e-commerce?</li>
                            <li><span className={styles.important}>PIM</span> - Product Information Management – do you manage your product information in the most efficient way?</li>
                            <li><span className={styles.important}>DXM</span> - Digital Experience Management</li>
                            <li><span className={styles.important}>Analytics</span> – do you track the right KPIs?</li>
                            <li><span className={styles.important}>Technology matching</span> – is your technology suitable for the type and scale of your e-commerce?</li>
                        </ul>
                        </div>
                        <a href="https://www.e-point.com/services/mobile/mobile-audyt" className={`${styles.btnDecorated}`}>Mobile ecosystem assessment</a>
                </div>
            </div>
        </div>
    )
}

export default Score
