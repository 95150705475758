import React from 'react'
import { form } from '../formData'

import styles from '../expandedResults.module.scss'

const Checkbox = ({ formIndex, handler, name }) => {

    return (
        <div className={styles.checkboxField} onChange={handler}>
            <p>{form[formIndex].checkbox[name].title}</p>
                {form[formIndex].checkbox[name].options.map((post, index) => (
                    <label key={post.name} htmlFor={`${post.name}-${index}`}>
                        <span>{post.name}</span>
                        <input value={post.score} name={name} id={`${post.name}-${index}`} type="checkbox" />
                    </label>
                ))}
        </div>
    )
}

export default Checkbox
