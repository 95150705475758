import React from 'react'
import Logo from '../../assets/epoint.png'

import styles from './watermark.module.scss'

const Watermark = () => {
    return (
        <div className={styles.watermark}> 
            <img src={Logo} alt="e-point homepage" />
        </div>
    )
}

export default Watermark
