import React from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Navbar from './Components/Navigation/navbar'
import Footer from './Components/Navigation/footer'

import Landing from './Components/Landing'
import ExpandedResults from './Components/ExpandedResults/expandedResults'
import ErrorPage from './Components/ErrorPage/errorPage'

import styles from './App.module.scss'

import { HelmetProvider } from 'react-helmet-async'
import ScrollToTop from './Components/ScrollToTop'
import ScrollTopArrow from './Components/Navigation/scrollTopArrow'

import GA from './GoogleAnalytics'

const title = 'e-commerce Audit Tool'

const App = () => {
  return (
    <BrowserRouter>
          <HelmetProvider>
            <div className={styles.App}>
              <Navbar />
              { GA.init() && <GA.RouteTracker /> }
                <Switch>
                  <Route exact path="/" component={() => <Landing title={title} />} />
                  <Route path="/expanded" component={() => <ExpandedResults title={title} />} />
                  <Route component={() => <ErrorPage />} />
                </Switch>
              <Footer />
            </div>
          </HelmetProvider>
          <ScrollTopArrow />
          <ScrollToTop/>
    </BrowserRouter>
  );
}

export default App
