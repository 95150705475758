export const form = [
    {
        title: "mEcosystem Self Assessment 2021",
        subtitle: "Instant results, no registration required",
        input: {
                main: "Enter MAIN PAGE URL of your e-shop please",
                category: "Enter CATEGORY PAGE URL of your e-shop",
                product: "Enter PRODUCT PAGE URL of your e-shop",
                checkout: "Enter CHECKOUT PAGE URL of your e-shop With couple of products in the cart",
            },
        checkbox: {
            "delivery_methods": {
                title: "What delivery methods are available in your e-shop?",
                options: [
                    {
                        name: "Courier",
                        score: 1,
                    },
                    {
                        name: "Click and Collect",
                        score: 1,
                    },
                    {
                        name: "Same day delivery",
                        score: 1,
                    },
                    {
                        name: "Traditional post office",
                        score: 1,
                    },
                    {
                        name: "parcel locker",
                        score: 1,
                    }
                ]
            },
            "payment_methods": {
                title: "What payment methods are available in your e-shop?",
                options: [
                    {
                        name: "Credit card",
                        score: 1,
                    },
                    
                    {
                        name: "Traditional Banking wires (transfers)",
                        score: 1,
                    },
                    {
                        name: "Electronic banking",
                        score: 1,
                    },
                    {
                        name: "Google Pay",
                        score: 1,
                    },
                    {
                        name: "Apple Pay",
                        score: 1,
                    },
                    {
                        name: "Check",
                        score: 1,
                    },
                    {
                        name: "Cash on delivery",
                        score: 1,
                    },
                    {
                        name: "Deferred payments",
                        score: 1,
                    },
                    {
                        name: "Instalments",
                        score: 1,
                    },
                    {
                        name: "Loans",
                        score: 1,
                    },
                    {
                        name: "Virtual product delivery",
                        score: 1,
                    },
                ],
            },
        },
    },
    {
        title: "Team and competencies section",
        question: "Is this area of expertise covered?",
        options: ["Covered - inhouse", "Covered - outsourcing", "Not covered"],
        radio: {
            "mobile_strategy": {
                title: "STRATEGY: Developing Mobile eCommerce Strategy",
            },
            "behaviour_analysis": {
                title: "MARKETING: Mobile Customer buying behaviour analysis",
            },
            "mobile_promotion": {
                title: "MARKETING: Planning and executing mobile promotion",
            },
            "building_community": {
                title: "MARKETING: Creating mobile content marketing that is focused on building community around your e-shop and/or brand",
            },
            "building_architecture": {
                title: "MOBILE eCOMMERCE IT: Analysing and building architecture of Mobile eCommerce platform",
            },
            "platform_implementation": {
                title: "MOBILE eCOMMERCE IT: Implementing of Mobile eCommerce platform",
            },
            "mobile_testing": {
                title: "MOBILE eCOMMERCE IT: Testing of Mobile eCommerce platform",
            },
            "mobile_maintenance": {
                title: "MOBILE eCOMMERCE IT: Maintenance of Mobile eCommerce platform",
            },
            "ux_interface": {
                title: "MOBILE eCOMMERCE IT: Designing of  UX mobile interface",
            },
            "mobile_commerce": {
                title: "MOBILE eCOMMERCE IT: Project management not only in eCommerce but also mobile eCommerce",
            },
        }
    },
    {
        title: "Product Information Management (PIM)",
        radio: {
            "keeping_information": {
                title: "Where do you keep information about products? (descriptions, parameters, prices etc.)",
                options: [
                    {
                        name: "Excel",
                        score: 0,
                    },
                    {
                        name: "ERP (Enterprise resource planning)",
                        score: 5,
                    },
                    {
                        name: "CRM (Customer Relationship Management)",
                        score: 5,
                    },
                    {
                        name: "PIM (Product Information Management)",
                        score: 10,
                    },
                ],
            },
            "transfer_information": {
                title: "What tools do you use to transfer product information across different departments?",
                options: [
                    {
                        name: "ZIP files (excel)",
                        score: 0,
                    },
                    {
                        name: "ERP (Enterprise resource planning)",
                        score: 5,
                    },
                    {
                        name: "CRM (Customer Relationship Management)",
                        score: 5,
                    },
                    {
                        name: "PIM (Product Information Management)",
                        score: 10,
                    },
                ],
            },
            "partners_complaining": {
                title: "Do your sales reps or external sales partners are complaining about outdated product information, description, prices etc.?",
                options: [
                    {
                        name: "Don't know",
                        score: 0,
                    },
                    {
                        name: "Always",
                        score: 0,
                    },
                    {
                        name: "Sometimes",
                        score: 0,
                    },
                    {
                        name: "Never",
                        score: 10,
                    },
                ],
            },
            "instant_access": {
                title: "Do your sales reps or external sales partners have instant access to an up to date prices and inventory status?",
                options: [
                    {
                        name: "Don't know",
                        score: 0,
                    },
                    {
                        name: "No",
                        score: 0,
                    },
                    {
                        name: "Yes",
                        score: 10,
                    },
                ],
            },
        }
    },
    {
        title: "Digital Experience Management",
        radio: {
            "digital_experience": {
                title: "Where do you keep information about Digital Experience (DX) of your customers?",
                options: [
                    {
                        name: "Don't know",
                        score: 0,
                    },
                    {
                        name: "Excel file",
                        score: 0,
                    },
                    {
                        name: "ERP",
                        score: 5,
                    },
                    {
                        name: "CRM",
                        score: 5,
                    },
                    {
                        name: "DXP",
                        score: 10,
                    },
                ]
            }
        },
    },
    {
        title: "Technology Matching",
        subTitle: "Is your technology suitable for type and scale of your business?",
        radio: {
            "technology_use": {
                title: "What technology do you use for your e-shop?",
                options: ["Shopify", "WooCommerce (Wordpress)", "PrestaShop", "Shopware", "Magento", "CommerceTools", "BigCommerce", "SAP Commerce (Hybris)", "Custom eCommerce Platform"],
            },
            "database_use": {
                title: "What type of database do you use?",
                options: ["Microsoft SQL", "Oracle / IBM", "MySQL / MariaDB", "Don't know"],
            },
            "product_variants": {
                title: "How many products and product variants do you have in your portfolio?",
                options: ["Less than 100", "100 - 10 000", "10 001 - 1 000 000", "Over 1 000 000"],
            },
            "business_size": {
                title: "How big is your business?",
                options: ["National", "International (several countries, languages)", "Global (multilangual, different continents, time zones, big teams)"],
            },
        }
    },
]