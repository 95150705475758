import React from 'react'
import styles from './contact.module.scss'
import HubspotForm from 'react-hubspot-form'

const Contact = () => {
    return (
        <div className={`${styles.container} ${styles.root}`}>
            <h2 className={`${styles.titleBig} ${styles.title}`}>Contact form</h2>
            <HubspotForm
                region='eu1'
                portalId='25315223'
                formId='ccc74ef8-eeec-4e28-8f26-d92dacda9d6b'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
        </div>
    )
}

export default Contact
