import React, { useState, useEffect } from 'react'

import styles from '../backendExpandedScore.module.scss'

import backendScoreTable from '../backendScoreTable'

import {ReactComponent as Poor} from '../../../../assets/icons/exclamation.svg'
import {ReactComponent as Excellent} from '../../../../assets/icons/tick.svg'

const OptimizationScore = ({ render, title, data, type }) => {

    const score = backendScoreTable[type]
    const dataType = Number(data[type])

    const [scoreText, setScoreText] = useState('')

    useEffect(() => {
        if(title === 'Covered - Inhouse') {
            setScoreText(score.coveredI)
        } else if(title === 'Covered - outsourcing') {
            setScoreText(score.coveredO)
        } else {
            setScoreText(score.not_covered)
        }
    }, [dataType, score, title])

    return (
        <div className={`${styles.root} ${title === 'Covered - Inhouse' || title === 'Covered - outsourcing' ? styles.ExcellentIcon : styles.PoorIcon} ${render ? styles.render : ''}`}>
            <h2 className={`${dataType === 1 ? styles.Excellent : styles.Poor}`}>{scoreText.score}</h2>
            <div className={styles.scoreWrapper}>
                <div className={styles.scoreContent}>
                    {scoreText.head && <h2>{scoreText.head}</h2>}
                    {scoreText.body && <p>{scoreText.body}</p>}
                    {scoreText.rec && <p>{scoreText.rec}</p>}
                    {scoreText.list && <ul>
                        {   scoreText.list.map((line) => (
                            <li key={line}>{line}</li>
                        ))}
                    </ul>}
                </div>
                <div className={styles.scoreSign}>
                    {title === 'Covered - Inhouse' || title === 'Covered - outsourcing' ? <Excellent /> : <Poor />}
                </div>
            </div>
        </div>
    )
}

export default OptimizationScore
