const storefrontScoresTable = {
    main: {
        "pwa": {
            "excellent": {
                score: 'Excellent',
                head: 'Well done! Your main page is mobile-friendly.',
                body: 'It is great to hear that your main page was build using mobile-friendly technology and it is working in a very efficient way on mobile devices.',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and main page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Warning! Your main page is not mobile-friendly.',
                body: 'It seems that your main page was build using some mobile-friendly technology but it is not working in the most efficient way on mobile devices.  There is a high probability, that you are losing money every day and generate a high bounce rate on the main page. Mobile customers do not receive a great mobile experience on the main page. Probably they are unable to properly navigate the site and find categories, products and promotions when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and main page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your main page is not mobile-friendly',
                body: 'It seems that your main page was build using old and not mobile-friendly technology. There is a high probability, that you are losing money every day and generate a high bounce rate on the main page. Mobile customers do not receive a great mobile experience on the main page. Probably they are unable to properly navigate the site and find categories, products and promotions when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and main page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            }
        },
        "perf": {
            "excellent": {
                score: 'Excellent',
                head: 'Well done! Your main page is extremely fast.',
                body: 'It is great to hear that the performance of your main page is excellent and it is working in a very efficient way on mobile devices.',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about performance.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Warning! Your main page is slow.',
                body: 'It seems that your main page has an average performance that can be easily improved. Remember the longer your main page loads the more money you lose because of increased bounce rate.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving the performance of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your main page is extremely slow',
                body: 'It seems that your main page is extremely slow on mobile devices. It means that you are losing money because mobile customers are not able to enter your shop at all or it takes ages to enter it when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving the performance of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
        "seo": {
            "excellent": {
                score: 'Excellent',
                head: 'Great job! Google loves your main page.',
                body: 'It is great to hear that SEO optimisation on your main page is top-notch.',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about SEO.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Requires attention! Your SEO for the main page is on average level.',
                body: 'It seems that your main page is partially SEO optimized. It means that there is a lot of space for improvement.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving SEO of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ]
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your main page is not SEO friendly',
                body: 'It seems that your main page is totally not SEO optimized. It means that there is no way your main page can appear in organic search. To sell you need to pay for ads over and over again. Building a strong SEO position means spending less on ads.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving SEO of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ]
            }
        },
    },
    category: {
        "pwa": {
            "excellent": {
                score: 'Excellent',
                head: 'Well done! Your category page is mobile-friendly.',
                body: 'It is great to hear that your category page was build using mobile-friendly technology and it is working in a very efficient way on mobile devices.  ',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and category page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Warning! Your category page is not mobile-friendly.',
                body: 'It seems that your category page was build using some mobile-friendly technology but it is not working in the most efficient way on mobile devices. Probably you are losing money on promotion (because of high bounce rate).',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and category page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your category page is not mobile-friendly.',
                body: 'It seems that your category page was build using old and not mobile-friendly technology. Probably you are losing money on promotion (because of high bounce rate). Mobile customers do not receive a great mobile experience on the category page. They are unable to properly search for products in the category when using a smartphone. Furthermore, the category page does not fulfil its primary function as an overview of products and promotions in a given category.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and category page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
        "perf": {
            "excellent": {
                score: 'Excellent',
                head: 'Well done! Your category page is extremely fast.',
                body: 'It is great to hear that the performance of your category page is excellent and it is working in a very efficient way on mobile devices.',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it. ",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about performance.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Requires attention! Your category page could be much faster.',
                body: 'It seems that your category page has an average performance that can be easily improved. Remember the longer your category page loads the more money you lose because of increased bounce rate.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving the performance of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your category page is extremely slow.',
                body: 'It seems that your category page is extremely slow on mobile devices. It means that you are losing money because mobile customers are not able to enter the category page at all or it takes ages to enter it when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving the performance of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
        "seo": {
            "excellent": {
                score: 'Excellent',
                head: 'Great job! Google loves your category page.',
                body: 'It is great to hear that SEO optimisation on your category page is top-notch. But remember that our tool has checked only one category page. To run advanced SEO analysis you should use dedicated SEO tools or contact our experts.',
                rec: 'We recommend',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about SEO.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Requires attention! Your SEO for the category page is on average level.',
                body: 'It seems that your category page is partially SEO optimized. It means that there is a lot of space for improvement. SEO of category page is a very important factor in inbound traffic.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving SEO of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your category page is not SEO friendly.',
                body: 'It seems that your category page is totally not SEO optimized. It means that there is no way your category page can appear in organic search. To sell you need to pay for ads over and over again. Building a strong SEO position means spending less on ads.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving SEO of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
    },
    product: {
        "pwa": {
            "excellent": {
                score: 'Excellent',
                head: 'Well done! Your product page is mobile-friendly.',
                body: 'It is great to hear that your category page was build using mobile-friendly technology and it is working in a very efficient way on mobile devices.  ',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and category page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Warning! Your product page is not mobile-friendly.',
                body: 'It seems that your product page was build using some mobile-friendly technology but it is not working in the most efficient way on mobile devices. Probably you are losing money because customers are unable to properly discover all benefits of the product or read the product description when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and category page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your product page is not mobile-friendly.',
                body: 'It seems that your product page was build using old and not mobile-friendly technology. You are losing money because customers who use mobile phones cannot buy the product easily. Mobile customers do not receive a great mobile experience on the product page. They are unable to properly discover all benefits of the product or read the product description when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and category page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
        "perf": {
            "excellent": {
                score: 'Excellent',
                head: 'Well done! Your category page is extremely fast.',
                body: 'It is great to hear that the performance of your product page is excellent and it is working in a very efficient way on mobile devices.',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it. ",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about performance.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Requires attention! Your product page could be much faster.',
                body: 'It seems that your product page has an average performance that can be easily improved. Remember the longer your product page loads the more money you lose because of increased bounce rate.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving the performance of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your product page is extremely slow.',
                body: 'It seems that your product page is extremely slow on mobile devices. It means that you are losing money because mobile customers are not able to enter the product page at all or it takes ages to enter it when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving the performance of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
        "seo": {
            "excellent": {
                score: 'Excellent',
                head: 'Great job! Google loves your product page.',
                body: 'It is great to hear that SEO optimisation on your product page is top-notch. But remember that our tool has checked only one product page. To run advanced SEO analysis you should use dedicated SEO tools or contact our experts.',
                rec: 'We recommend',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about SEO.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Requires attention! Your SEO for the product page is on average level.',
                body: 'It seems that your product page is partially SEO optimized. It means that there is a lot of space for improvement. SEO of category page is a very important factor in inbound traffic.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving SEO of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your product page is not SEO friendly.',
                body: 'It seems that your product page is totally not SEO optimized. It means that there is no way your product page can appear in organic search. Product pages are the most important pages and have to be SEO friendly. To sell you need to pay for ads over and over again. Building a strong SEO position means spending less on ads.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving SEO of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
    },
    checkout: {
        "pwa": {
            "excellent": {
                score: 'Excellent',
                head: 'Well done! Your checkout page is mobile-friendly.',
                body: 'It is great to hear that your checkout page was build using mobile-friendly technology and it is working in a very efficient way on mobile devices.',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and checkout page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Requires attention! Your checkout page could be more mobile-friendly.',
                body: 'It seems that your checkout page was build using some mobile-friendly technology but it is not working in the most efficient way on mobile devices. Mobile customers do not receive a great mobile experience on the last step of buying journey which is the checkout page.  They are unable to finish their shopping when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and category page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your checkout page is not mobile-friendly.',
                body: 'It seems that your checkout page was build using old and not mobile-friendly technology. You are losing money because customers can not finish the buying process in an easy way. Mobile customers do not receive a great mobile experience on the last step of buying journey which is the checkout page.  They are unable to finish their shopping when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about mobile-friendly technology and category page improvement.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
        "perf": {
            "excellent": {
                score: 'Excellent',
                head: 'Well done! Your checkout page is extremely fast.',
                body: 'It is great to hear that the performance of your checkout page is excellent and it is working in a very efficient way on mobile devices.',
                rec: 'We recommend:',
                list: [
                    "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it. ",
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about performance.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "average": {
                score: 'Average',
                head: 'Requires attention! Your checkout page could be much faster.',
                body: 'It seems that your checkout page has an average performance that can be easily improved. Remember the longer your checkout page loads the more money you lose because of the increased cart abandonment.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving the performance of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
            "poor": {
                score: 'Poor',
                head: 'Warning! Your checkout page is extremely slow.',
                body: 'It seems that your checkout page is extremely slow on mobile devices. It means that you are losing money because mobile customers are not able to enter the product page at all or it takes ages to enter it when using a smartphone.',
                rec: 'It is advised to take immediate action. We recommend:',
                list: [
                    "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving the performance of your e-shop.",
                    "You can also contact our Mobile eCommerce Experts for support.",
                ],
            },
        },
    },
}

export default storefrontScoresTable