import React from 'react'

import Checkbox from './custom/checkbox'
import Teams from './custom/teams'
import RadioPIM from './custom/radioPIM'
import Technology from './custom/technology'
import InputField from './custom/inputField'

import { form } from './formData'

import styles from './expandedResults.module.scss'

const ExpandedForm = (props) => {


    const {
        validateSearch,
        handleMainChange,
        handleCategoryChange,
        handleProductChange,
        handleFormSubmit,
        handleCheckoutMainChange,
        handleDeliveryCheck,
        handlePaymentCheck,
        handleDigitalExperienceRadio,
        handleTechnologyUse,
        handleDatabaseUse,
        handleProductVariants,
        handleBusinessSize,

        handleTeamsRadio,
        handlePimRadio,
    } = props

    return (
            <form onSubmit={handleFormSubmit} method="post" className={`${styles.root}`}>
                <section className={styles.storefront}>
                    <h2 className={styles.title}>{form[0].title} <span className={styles.secTitle}>{form[0].subtitle}</span></h2>
                    <h2 className={styles.subTitle}>Check your whole eCommerce Ecosystem and find out where to start your improvements.</h2>
                    <InputField title={form[0].input.main} onBlur={validateSearch} type="text" handler={handleMainChange} placeholder="https://" required/>
                    <InputField title={form[0].input.category} onBlur={validateSearch} type="text" handler={handleCategoryChange} placeholder="https://" required/>
                    <InputField title={form[0].input.product} onBlur={validateSearch} type="text" handler={handleProductChange} placeholder="https://" required/>
                    <InputField title={form[0].input.checkout} onBlur={validateSearch} type="text" handler={handleCheckoutMainChange} placeholder="https://" required/>
                    <small className={styles.textCenter}>Make sure you inserted correct URL's</small>
                    <Checkbox formIndex={0} handler={handleDeliveryCheck} name="delivery_methods" />
                    <Checkbox formIndex={0} handler={handlePaymentCheck} name="payment_methods" />
                </section>
                <section className={styles.competencies}>
                    <h2 className={styles.subTitle}>{form[1].title}</h2>
                    <p>{form[1].subTitle}</p>
                    <Teams formIndex={1} handler={handleTeamsRadio('mobile_strategy')} name="mobile_strategy" />
                    <Teams formIndex={1} handler={handleTeamsRadio('behaviour_analysis')} name="behaviour_analysis" />
                    <Teams formIndex={1} handler={handleTeamsRadio('mobile_promotion')} name="mobile_promotion" />
                    <Teams formIndex={1} handler={handleTeamsRadio('building_community')} name="building_community" />
                    <Teams formIndex={1} handler={handleTeamsRadio('building_architecture')} name="building_architecture" />
                    <Teams formIndex={1} handler={handleTeamsRadio('platform_implementation')} name="platform_implementation" />
                    <Teams formIndex={1} handler={handleTeamsRadio('mobile_testing')} name="mobile_testing" />
                    <Teams formIndex={1} handler={handleTeamsRadio('mobile_maintenance')} name="mobile_maintenance" />
                    <Teams formIndex={1} handler={handleTeamsRadio('ux_interface')} name="ux_interface" />
                    <Teams formIndex={1} handler={handleTeamsRadio('mobile_commerce')} name="mobile_commerce" />
                </section>
                <section className={styles.management}>
                    <h2 className={styles.subTitle}>{form[2].title}</h2>
                    <RadioPIM formIndex={2} handler={handlePimRadio('keeping_information')} name="keeping_information" />
                    <RadioPIM formIndex={2} handler={handlePimRadio('transfer_information')} name="transfer_information" />
                    <RadioPIM formIndex={2} handler={handlePimRadio('partners_complaining')} name="partners_complaining" />
                    <RadioPIM formIndex={2} handler={handlePimRadio('instant_access')} name="instant_access" />
                </section>
                <section className={styles.informationManagement}>
                    <h2 className={styles.subTitle}>{form[3].title}</h2>                 
                    <RadioPIM formIndex={3} handler={handleDigitalExperienceRadio} name="digital_experience" />
                </section>
                <section className={styles.technology}>
                    <h2 className={styles.subTitle}>{form[4].title}</h2>
                    <p>{form[4].subTitle}</p>
                    <Technology handler={handleTechnologyUse} name="technology_use" />
                    <Technology handler={handleDatabaseUse} name="database_use" />
                    <Technology handler={handleProductVariants} name="product_variants" />
                    <Technology handler={handleBusinessSize} name="business_size" />
                </section>
                <section className={styles.submit}>
                    <button className={styles.btnSubmit}>Submit</button>
                </section>
            </form>
    )
}

export default ExpandedForm
