import React from 'react'

import trustPNG from '../../assets/trusted_companies.png'
import trustWEBP from '../../assets/trusted_companies.webp'

import ImgWithFallback from '../ImgWithFallback'
import styles from './trusted.module.scss'

const Trusted = () => {
    return (
        <div className={`${styles.trusted} ${styles.containerSmall}`}>
            <h2 className={`${styles.title} ${styles.titleCenter} ${styles.titleBig}`}>They trusted us</h2>
            <picture>
                <ImgWithFallback
                    src={trustWEBP}
                    fallback={trustPNG}
                    alt="Companies that trusted us"
                />
            </picture>
        </div>
    )
}

export default Trusted