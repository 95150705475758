import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

ReactGA.initialize('UA-215985782-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
    gtmId: 'GTM-KRQJZT4'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
