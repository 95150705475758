import React from 'react'
import styles from './errorPage.module.scss'

const ErrorPage = () => {
    return (
        <>
            <div className={styles.expandedLanding}></div>
            <h2 className={`${styles.title} ${styles.textCenter}`}>404</h2>
        </>
    )
}

export default ErrorPage
