import React, { useState, useEffect, useRef, Suspense } from "react";
import axios from "axios";
import styles from './landing.module.scss'

import Score from "../Measure/score";
import Spinner from "../Spinner/spinner";

import Trusted from '../Trusted'

import { Helmet } from 'react-helmet-async';
import ProgressBar from "../ProgressBar/progressBar";

import { GoogleSpreadsheet } from "google-spreadsheet";
import SeeAlso from "../SeeAlso/seeAlso";
import Contact from "../Contact/contact";

const MobileCommerce = React.lazy(() => import('../MobileCommerce'));
const Interlude = React.lazy(() => import('../Interlude'));
const Plans = React.lazy(() => import('../Plans'));
const Invitation = React.lazy(() => import('../Invitation'));

const Landing = ({ title }) => {

      const [indicator, setIndicator] = useState(0)
      
      const [url, setUrl] = useState("");
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(false);
      const [data, setData] = useState([]);
      const [performance, setPerformance] = useState(0);
      const [pwa, setPwa] = useState(0);
      const [seo, setSeo] = useState(0);
      const [audits, setAudits] = useState([]);
      
      const handleChange = (e) => {
        setUrl(e.target.value.toLowerCase());
      };

      const validateSearch = () => {
        if (url.indexOf("https://") === -1 || url.indexOf("http") === -1) {
          setUrl("https://" + url);
        } else {
          return true;
        }
      };
      
      const mounted = useRef();
      useEffect(() => {
        if (!mounted.current) {
          mounted.current = true;
        } else {
          validateSearch();
        }
      })

      useEffect(() => {
        let interval = setInterval(() => indicator < 100 && loading && setIndicator(indicator + Math.random() * 8), 1500)
        return () => {
          clearInterval(interval)
        }
      }, [indicator, loading])

      const handleSearch = (e) => {
        e.preventDefault();
        
        if (validateSearch()) {
          setError(false);
          setLoading(true);
          setIndicator(0)
          axios
          .get(
            `https://pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed?category=PWA&category=PERFORMANCE&category=SEO&strategy=MOBILE&url=${url}&key=AIzaSyCjj77P6ztAkz83iT2ahw4huFVOO9C1PlE`
            )
            .then((result) =>
              setTimeout(() => {
                setLoading(false)
                setData(result.data.lighthouseResult);
                setAudits(result.data.lighthouseResult.audits);
                setPerformance(result.data.lighthouseResult.categories.performance['score'])
                setPwa(result.data.lighthouseResult.categories.pwa['score'])
                setSeo(result.data.lighthouseResult.categories.seo['score'])
                // ADD TO STYLESHEET
                  const doc = new GoogleSpreadsheet('1Fty48b98m6Gx0kNOT0Y61F1IxIPPU17kjD5dO72NdxM');
                  const appendSpreadsheet = async (row) => {
                    try {
                      await doc.useServiceAccountAuth({
                        client_email: 'mci-121@mindex-tool-1601648592701.iam.gserviceaccount.com',
                        private_key: "-----BEGIN PRIVATE KEY-----MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCm7fItB6l6TBtD\n8zUsv0mfx4+sKvHkClgOMjmZqDTQhdpr42rxndoT6IhKBxJkAjnoMrx4RibrJrN1\nLtSZy6TpNrf8mmFlltKoupFNaH78q6VPgk1T+JC/8HQRtdyXXDjaBdYyTsP3i0EP\n+nQoKWBJKG0oS53XgrbdMSa5v3PgcfbeeI7jdimPN4aVff3vi8sZyrUdEcD0ayYk\nGOxC91WmZA1Y0X6TtmpIR7H0+7eY1RhdZKV2A+LIMm/8aKDqXCeGf7+N2LwMcBvi\ncFx31aF4BAdaG9RT5NEW9Kf3M5N2wmZ7PMF/ndxu0eIa44ocJzB+C4z2K+5+my+/\n+86gn4qvAgMBAAECggEAAqC5OqQ+Y/2M4FSobGiGfWoLcUUrzLzApETgmgYHPZYK\nKA4tX0XkkeTkdA1EI+0NqTzgxSA/b6w7/IcJH+jVGBMThXndm5JDVD3jBOtmqoQ5\nOV7krQzO1Z4THRMHh4CdFIBU3fe4sRydUeXXwB8GoGUgXxdAarRoshSQnfZq1OEN\na2rFhE6VN43L6Wz13nU9LHABnRWoncoXPGSuoBPRcWQwfWk+Rg8ndDUqRpN/dZ7C\nchblxvWnvVwyXq43d9HzKp8NrATbtLF/GW8xk2RWjJnnwCNuDywMXQuJMdJXBNIC\n4SKgg3cgQGTCe6h7csT36o3Sv08FerB5wgs2R6SDQQKBgQDcJ5mPmaL2lWDEHywR\n9ntpE/9qVyQGs6fBz3NOfgqpS99adafV/lCEFn8VYIhvJfV8mlh9EkdG+j3JRDTf\nNpmbl/tk5Ddxccp3XlaLQ634CixV6MYnvUIc+O3iFEp/Ir2ae+DyCaw+HxO3xhOL\nT1nE/A9pXJlmv1+b0JZtF7xb2QKBgQDCG9WG9gt46pWwj3OdP4bkeEEzhXDunO67\nqz9mExxlbh3scIOGGA8/romxt2+5UkGMbxyHmEDdBbTj2UTIrdWoChATz7YgdLVY\nghTLclzuI79AF0r5cAOhSwV0KTDx37Y7OLSATS+vOkRI643eiYLsbYy6QxZrYOoA\nfWT+yyctxwKBgAU4YynGiNiz/hFoc9EROSfqs3nACzildergkmlgD7yn8enhNFOc\nrigTqHvHSDTqf4Gu3z5H+qyyLljhZyeL0eSgm/NufeoQuq5N7FttBWkGRLrcxCYZ\nFMI/HNTpikKtfjV1joXuuMjYT81hwwDgps5G886ZxYFsNEYrkgWQNIUxAoGAFNkm\newNsQgV4JGmT8lzDYUClSCJZ+dbzMUb4XT9KOn60ShPfnIRzBtT5RxQ7tLzn+2xn\ntQyeUt7tCdfpMmCxZQYHqgkSN7QxUhtOnndfEdjXDswxqk7kpOtI36wY7LGpgfza\nLjmcGIb6lJZwO2mrnTc6+TqBavES0RCmy1MIlzsCgYEAoXhFgUiswRVDZPavi2Gx\nOv/tZI7ZwEoPkVnSIOfqY9Hg4kA8E+1N2/ADoA7ONGNZRUO0mAfCwNVrJh1bpxqx\ngBWr00ss57JM0La24a8TnUGHRTeSIYm2F5c5lZ3pjXjOzHDvSUo3zRrIFTWM26wU\nYGD1yhV9VY+lfxmUUibcf6M=\n-----END PRIVATE KEY-----",
                      });
                      // loads document properties and worksheets
                      await doc.loadInfo();

                      const sheet = doc.sheetsById["0"];
                      // eslint-disable-next-line no-unused-vars
                      const result = await sheet.addRow(row);
                    } catch (e) {
                      console.error('Error: ', e);
                    }
                  };
                  const dt = new Date();
                  appendSpreadsheet({ Link: url, Data: dt});
                }, 1000)
            )
            .then(() => {
              setIndicator(100)
            })
            .catch(function (error) {
              if (error) {
                setLoading(false);
                setError(true);
              }
            });
          }
      };

    return (
        <>
        <Helmet>
          <title>{`${title}`}</title>
        </Helmet>
            <div className={styles.landing}>
                <div className={styles.measure}>
                    <div className={styles.containerSmall}>
                        <h2 className={`${styles.title} ${styles.titleCenter} ${styles.titleBig}`}>
                            e-commerce Audit Tool
                        </h2>
                        <p className={`${styles.subTitle} ${styles.titleCenter}`}>
                            Check your e-commerce performance, mobile friendliness (PWA) and SEO score – and find where to improve your m-shop for customers!
                        </p>
                        </div>
                        <div className={styles.measureResults}>
                        <div className={`${styles.measureWrapper} ${styles.containerSmall}`}>
                            <form onSubmit={handleSearch}>
                            <div className={styles.inputField}>
                                <label htmlFor="siteurl" className={styles.srOnly}>
                                Website URL
                                </label>
                                <input
                                type="text"
                                name="siteurl"
                                placeholder="Your e-commerce URL"
                                onChange={handleChange}
                                id="siteurl"
                                required
                                />
                                <span></span>
                                <input
                                type="reset"
                                value="x"
                                className={styles.btnClose}
                                aria-label="Remove URL"
                                />
                            </div>
                            <button
                                type="submit"
                                className={`${styles.btnSubmit} ${loading ? styles.disabled : ''}`}
                            >
                                Start!
                            </button>
                            </form>
                            <div className={styles.xtraSpace}>
                            {loading ? 
                              <div><ProgressBar completed={indicator} /> <span>We are gathering data</span></div>
                             : null}
                            {error ? (
                                <p className={styles.error}>Incorrect URL or too many requests!</p>
                            ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {audits.length !== 0 && <Score performance={performance} data={data} pwa={pwa} seo={seo} />}
              <Trusted />
              <Suspense fallback={<Spinner />}>
                  <MobileCommerce />
                  <SeeAlso />
                  <Interlude />
                  <Plans />
                  <Invitation />
                  <Contact />
              </Suspense>
        </>
    );
}

export default Landing
