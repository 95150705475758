import React, { useState } from 'react'
import styles from './navbar.module.scss'
import Logo from '../../assets/epoint.png'

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [dropdownOpenSol, setDropdownOpenSol] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const navTrigger = () => {
        setIsOpen(!isOpen)
    }

    const handleHoverDropdownSol = (e) => { 
        e.preventDefault()
        setDropdownOpenSol(!dropdownOpenSol) }

    const handleHoverDropdown = (e) => { 
        e.preventDefault()
        setDropdownOpen(!dropdownOpen) }

    return (
        <header className={styles.navbar}>
            <nav className={styles.wrapper}>
                <div className={styles.logo}>
                    <h1 className={styles.logoH}>
                        <span className={styles.srOnly}>mCommerceIndex tool</span>
                        <a href="https://e-point.com/"><img src={Logo} alt="e-point" /></a>
                    </h1>
                </div>
                <ul className={`${styles.navList} ${isOpen ? styles.active : ''}`}>
                    <li><a href="/">e-commerce Audit Tool</a></li>
                    <li className={styles.dropdownItem}>
                        <a onClick={handleHoverDropdownSol} className={`${styles.dropdown} ${dropdownOpenSol ? styles.active : ''}`} href="https://e-point.com">Solutions</a>
                        <ul className={`${styles.dropdownList} ${dropdownOpenSol ? styles.dropdownOpen : ''}`}>
                            <li><a href="https://www.e-point.com/services/mobile/mobile-audyt">Mobile UX and performance audit</a></li>
                            <li><a href="https://www.e-point.com/services/e-commerce">e-commerce development and design services</a></li>
                            <li><a href="https://www.e-point.com/services/mobile/pwa">Progressive Web Apps (PWA)</a></li>
                            <li><a href="https://www.e-point.com/services/mobile/flutter">Mobile app development (Flutter)</a></li>
                            <li><a href="https://www.e-point.com/services/mobile/react-native">Mobile app development (React Native)</a></li>
                        </ul>
                    </li>
                    <li><a href="https://www.e-point.com/blog">Blog</a></li>
                    <li className={styles.dropdownItem}>
                        <a onClick={handleHoverDropdown} className={`${styles.dropdown} ${dropdownOpen ? styles.active : ''}`} href="https://e-point.com">About us</a>
                        <ul className={`${styles.dropdownList} ${dropdownOpen ? styles.dropdownOpen : ''}`}>
                            <li><a href="mailto:contact@e-point.com">Contact</a></li>
                            <li><a href="https://www.e-point.com/">Visit e-point.com</a></li>
                        </ul>
                    </li>
                </ul>
                <div className={styles.menuToggleWrapper}>
                    <button className={`${styles.menuToggle} ${isOpen ? styles.active : ''}`} aria-label="Main menu" onClick={navTrigger}>
                        <span className={styles.srOnly}>Expand main menu</span>
                        <span className={styles.toggleNav}></span>
                    </button>
                </div>
            </nav>
        </header>
    )
}

export default Navbar
