import React, { useState, useEffect } from 'react'

import styles from '../backendExpandedScore.module.scss'

import backendScoreTable from '../backendScoreTable'

import {ReactComponent as Poor} from '../../../../assets/icons/exclamation.svg'
import {ReactComponent as Average} from '../../../../assets/icons/warning.svg'
import {ReactComponent as Excellent} from '../../../../assets/icons/tick.svg'

const PIMScore = ({ render, data, type }) => {

    const score = backendScoreTable[type]

    const [isLoaded, setIsLoaded] = useState(false)
    const [scoreText, setScoreText] = useState('')

    useEffect(() => {
        if(Number(data[type]) === score.threshold[0]*score.max_score) {
            setScoreText(score.excellent)
        } else if(Number(data[type]) >= score.threshold[1]*score.max_score) {
            setScoreText(score.average)
        } else {
            setScoreText(score.poor)
        }
        setIsLoaded(true)
    }, [data, score, type])

    return (
        isLoaded ?
        <div className={`${styles.root} ${scoreText.score === 'Excellent' ? styles.ExcellentIcon : scoreText.score === 'Average' ? styles.AverageIcon : styles.PoorIcon} ${render ? styles.render : ''}`}>
            <h2 className={`${styles[scoreText.score]}`}>{scoreText.score}</h2>
            <div className={styles.scoreWrapper}>
                <div className={styles.scoreContent}>
                    {scoreText.head && <h2>{scoreText.head}</h2>}
                    {scoreText.body && <p>{scoreText.body}</p>}
                    {scoreText.rec && <p>{scoreText.rec}</p>}
                    {scoreText.list && <ul>
                        {   scoreText.list.map((line) => (
                            <li key={line}>{line}</li>
                        ))}
                    </ul>}
                </div>
                <div className={styles.scoreSign}>
                    { scoreText.score === 'Excellent' ? <Excellent /> : scoreText.score === 'Average' ? <Average /> : <Poor /> }
                </div>
            </div>
        </div>
        : null
    )
}

export default PIMScore
