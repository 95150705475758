import React from 'react'
import styles from './seeAlso.module.scss'

const SeeAlso = () => {
    return (
        <div className={`${styles.root} ${styles.container}`}>
            <h2 className={`${styles.textCenter} ${styles.title}`}>See also...</h2>
            <div className={styles.seeWrapper}>
                <div className={styles.content}>
                    <h2 className={`${styles.title}`}>Read our articles about mobile solutions on e-point's blog</h2>
                    <p>For 25 years we have been establishing, developing and maintaining portals as well as platforms supporting sales. We also provide mobile solutions (apps, audits, PWAs).</p>

                    <a rel="noopener noreferrer" target="_blank" className={styles.btnDecoratedBorder} href="https://www.e-point.com/about-us">About us</a>
                    <a rel="noopener noreferrer" target="_blank" className={styles.btnDecorated} href="https://www.e-point.com/blog">See our blog</a>
                </div>
                <div className={styles.more}>
                    <h2 className={styles.title}>Check it out</h2>
                </div>
            </div>
        </div>
    )
}

export default SeeAlso
