import React, { useEffect, useState } from 'react'
import styles from '../../Measure/score.module.scss'

import ScoreBox from '../../Measure/scoreBox';

const StorefrontExpandedScore = ({ isCheckout, render, data, type, performance, pwa, seo }) => {
        
    const [pwaText, setPwaText] = useState([])
    
    const [perfText, setPerfText] = useState([])

    const [seoText, setSeoText] = useState([])

    const table = data[type];

    useEffect(() => {
        if(pwa >= 0.9) {
            setPwaText(table.pwa.excellent)
        } else if(pwa >= 0.5) {
            setPwaText(table.pwa.average)
        } else {
            setPwaText(table.pwa.poor)
        }
    }, [pwa, table])
    
    useEffect(() => {
        if(performance >= 0.9) {
            setPerfText(table.perf.excellent)
        } else if(performance >= 0.5) {
            setPerfText(table.perf.average)
        } else {
            setPerfText(table.perf.poor)
        }
    }, [performance, table])
    
    useEffect(() => {
        if(!isCheckout) {
            if(seo >= 0.9) {
                setSeoText(table.seo.excellent)
            } else if(seo >= 0.5) {
                setSeoText(table.seo.average)
            } else {
                setSeoText(table.seo.poor)
            }
        }
    }, [seo, isCheckout, table])

    return (
        <div className={styles.score}>
            <ScoreBox render={render} coloredBackground totalScore={performance} score={perfText.score}>
                    <h3 className={styles.subTitle}>{perfText.head}</h3>
                    <p>{perfText.body}</p>
                    <p>{perfText.rec}</p>
                    <ul className={styles.scoreBoxList}>
                        {perfText.list &&
                            perfText.list.map((line) => (
                            <li key={line}>{line}</li>
                        ))}
                    </ul>
                    <div className={styles.scoreLinks}>
                        <a rel="noopener noreferrer" target="_blank" href='https://e-point.expert/mobile-ecommerce-ultimate-guide/' className={styles.btnDecoratedBorder}>
                            Visit our ultimate guide
                        </a>
                    </div>
            </ScoreBox>
            {!isCheckout &&
            <ScoreBox render={render} totalScore={seo} score={seoText.score}>
                    <h3 className={styles.subTitle}>{seoText.head}</h3>
                    <p>{seoText.body}</p>
                    <p>{seoText.rec}</p>
                    <ul className={styles.scoreBoxList}>
                        {seoText.list &&
                            seoText.list.map((line) => (
                            <li key={line}>{line}</li>
                        ))}
                    </ul>
                    <div className={styles.scoreLinks}>
                        <a rel="noopener noreferrer" target="_blank" href='https://e-point.expert/mobile-ecommerce-ultimate-guide/' className={styles.btnDecoratedBorder}>
                            Visit our ultimate guide
                        </a>
                    </div>
            </ScoreBox>
            }
            <ScoreBox render={render} coloredBackground={!isCheckout} totalScore={pwa} score={pwaText.score}>
                    <h3 className={styles.subTitle}>{pwaText.head}</h3>
                    <p>{pwaText.body}</p>
                    <p>{pwaText.rec}</p>
                    <ul>
                        {pwaText.list &&
                            pwaText.list.map((line) => (
                            <li key={line}>{line}</li>
                        ))}
                    </ul>
                    <div className={styles.scoreLinks}>
                        <a rel="noopener noreferrer" target="_blank" href='https://e-point.expert/mobile-ecommerce-ultimate-guide/' className={styles.btnDecoratedBorder}>
                            Visit our ultimate guide
                        </a>
                    </div>
            </ScoreBox>
        </div>
    )
}

export default StorefrontExpandedScore
