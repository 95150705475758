import React, { useEffect, useState } from 'react'
import styles from './score.module.scss'
import GaugeChart from 'react-gauge-chart'

const ScoreBox = ({ render, coloredBackground, totalScore, score, children }) => {

    // SET UP DIMENSIONS SO GAUGE DON'T GLITCH AFTER WINDOW RESIZING
    const [dimensions, setDimensions] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth)  
    }
    window.addEventListener('resize', handleResize)
    return(() => {
        window.removeEventListener('resize', handleResize)
    })
  })

    return (
        <div className={`${styles.scoreBox} ${render ? styles.render : ''} ${coloredBackground ? styles.colored : ''}`}>
            <div className={styles.container}>
                <div className={styles.scoreBoxContent}>
                    {children}
                </div>
                <div className={`${styles.scoreBoxIndicator} ${styles[score]}`}>
                    <GaugeChart
                    dimensions={dimensions}
                    animate={false}
                    percent={totalScore}
                    arcsLength={[0.5, 0.4, 0.1]}
                    colors={["#E31212", "#FFC700", "#06BD18"]}
                    needleColor="#202124"
                    formatTextValue={() => ""}
                    />
                 <p className={`${styles.totalScore} ${styles.textCenter} ${styles.gaugeText}`}>{score}</p>
                </div>
            </div>
        </div>
    )
}

export default ScoreBox
