// THRESHOLD (PERCENT 0.1 - 1) = [1. EXCELLENT SCORE, 2. AVERAGE SCORE]
// EXAMPLE: IF = 1 (NEED 5/5 TO GET EXCELLENT); IF = 0.6 (NEED 3/5 TO GET AVERAGE)
// REPLACE_SCORE IS WORKING AS VARIABLE

const backendScoresTable = {
    "delivery_methods": {
        threshold: [1, 0.6],
        max_score: 5,
        "excellent": {
            score: 'Excellent',
            head: 'Well done! You have REPLACE_SCORE/5 delivery methods available in your e-shop.',
            body: 'It is great to hear that you have 5/5 delivery methods available in your e-shop that are mentioned in our survey.',
            rec: 'We recommend:',
            list: [
                "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about delivery methods and trends.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
        "average": {
            score: 'Average',
            head: 'Requires attention! You could offer more delivery methods.',
            body: 'You have only REPLACE_SCORE/5 delivery methods available. It is a risk of losing money. Customers are looking for the most convenient way of delivery on the market. It is a high risk that they will choose your competition.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "You should consider implementing more delivery methods.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about delivery methods.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
        "poor": {
            score: 'Poor',
            head: 'Warning! A small number of delivery methods',
            body: 'You have only REPLACE_SCORE/5 delivery methods available. It is a risk of losing money. Customers are looking for the most convenient way of delivery on the market. It is a high risk that they will choose your competition.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "You should consider implementing more delivery methods.",
                "Read more about delivery methods in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
    },
    "payment_methods": {
        threshold: [1, 0.4],
        max_score: 11,
        "excellent": {
            score: 'Excellent',
            head: 'Well done! You have REPLACE_SCORE/11 payment methods available in your e-shop.',
            body: 'It is great to hear that you have REPLACE_SCORE/11 payment methods available in your e-shop that are mentioned in our survey.',
            rec: 'We recommend:',
            list: [
                "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about delivery methods and trends.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
        "average": {
            score: 'Average',
            head: 'Warning! A small number of payment methods',
            body: 'You have only REPLACE_SCORE/11 payment methods available. It is a risk of losing money. Mobile customers want to pay using their preferred and mobile-friendly payment method.',
            rec: 'Recommendations:',
            list: [
                "You should consider implementing more payment methods.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about delivery methods.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
        "poor": {
            score: 'Poor',
            head: 'Warning! A small number of payment methods',
            body: 'You have only REPLACE_SCORE/11 delivery methods available. It is a risk of losing money. Customers are looking for the most convenient way of delivery on the market. It is a high risk that they will choose your competition.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "You should consider implementing more delivery methods.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about delivery methods.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "mobile_strategy": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got a Mobile eCommerce Strategy competency covered in-house.',
            body: 'Running Mobile eCommerce requires an expert that can develop and implement strategy taking into account the mobile perspective. Traditional eCommerce strategist may not be enough. It is great to hear that you have that specialist on board.',
            rec: 'Recommendations:',
            list: [
                "Read more about Mobile eCommerce Strategy in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got a Mobile eCommerce Strategy competency covered by an external company.',
            body: 'Running Mobile eCommerce requires an expert that can develop and implement strategy taking into account the mobile perspective. Traditional eCommerce strategist may not be enough. It is great to hear that your company has acess to such a specialist.',
            rec: 'Recommendations:',
            list: [
                "Consider hiring such a specialist in-house because it is a fundamental competency.",
                "Read more about Mobile eCommerce Strategy in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your company lacks expertise in the field of Developing Mobile eCommerce Strategy',
            body: 'Running Mobile eCommerce requires an expert that can develop and implement strategy taking into account mobile perspective. Traditional eCommerce strategist may not be enough.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "You should consider hiring or outsource a Mobile eCommerce Strategist.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about team building and competencies including Mobile eCommerce Strategy.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "behaviour_analysis": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got a Mobile Customer buying behaviour analysis competency covered in-house',
            body: "Mobile Customers and their buying behaviour is a key to success for your Mobile eCommerce. That's great that you have this competency in-house.",
            rec: 'Recommendations:',
            list: [
                "Read more about improving marketing team and competencies including Mobile Customer buying behaviour analysis in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got a Mobile Customer buying behaviour analysis competency covered by an external company.',
            body: 'Running Mobile eCommerce requires an expert that can develop and implement strategy taking into account mobile perspective. Traditional eCommerce strategist may not be enough. It is great to hear that your company has an access to such a specialist.',
            rec: 'Recommendations:',
            list: [
                "Read more about improving marketing team and competencies including Mobile Customer buying behaviour analysis and what to expect from the external agency in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your marketing team lacks expertise in the field of Mobile Customer buying behaviour analysis.',
            body: 'Mobile Customers and their buying behaviour is a key to success for your Mobile eCommerce. Your company do not analyse buying behaviour so it is a great risk that strategy and crucial decisions are made based on feeling, not data analysis.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "You should consider hiring or outsource a Mobile Customer Buying Behaviour Analitic.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving marketing team and competencies including Mobile Customer buying behaviour analysis.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "mobile_promotion": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got expertise in the field of "Planning and executing mobile promotion"',
            body: 'Mobile promotion must be included in the promotion strategy. It is great that your company has got an expert to plan and run effective campaigns in-house.',
            rec: 'Recommendations:',
            list: [
                "Read more about planning and executing mobile promotion in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got a "Planning and executing mobile promotion" competency covered by an external company.',
            body: 'Mobile promotion must be included in the promotion strategy. It is great that your company has an agency that covers this area of expertise.',
            rec: 'Recommendations:',
            list: [
                "Read more about planning and executing mobile promotion in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your marketing team lacks expertise in the field of "Planning and executing mobile promotion"',
            body: 'Mobile promotion must be included in the promotion strategy. Your company needs an expert in this field to run effective campaigns and to counteract the waste of advertising budget generating internet traffic to your store that comes from mobile devices.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "You should consider hiring or outsource a marketing specialist that is familiar with Planning and executing the mobile promotion.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving marketing team and competencies including Planning and executing the mobile promotion.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "building_community": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got expertise in the field of mobile content marketing.',
            body: "Product pages generate revenue. Content pages generate a community. It's great to hear that your company has experience in the mobile content marketing field and creates ultra-relevant content. It means that mobile customers can find useful information and can learn from your content before the purchase.",
            rec: 'Recommendations:',
            list: [
                "Read more about improving marketing team and competencies including content marketing in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area."
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has mobile content marketing competency covered by an external company.',
            rec: 'Recommendations:',
            body: "Product pages generate revenue. Content pages generate a community. It's great to hear that your company has an external agency that supports you in this field. an and creates ultra-relevant content. It means that mobile customers can find useful information and can learn from your content before the purchase.",
            list: [
                "Read more about improving marketing team and competencies including content marketing in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: '',
            body: "Product pages generate revenue. Content pages generate a community. There is a big risk that your company doesn't create ultra-relevant content. It means that mobile customers can find only products but no additional content that can attract mobile customers to the brand",
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                'You should consider hiring or outsource a marketing specialist that is familiar with "Creating mobile content marketing that is focused on building a community around your e-shop".',
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving marketing team and competencies including content marketing.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "building_architecture": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got expertise in the field of "Analysing and building architecture of Mobile eCommerce Platform"',
            body: 'Since you have in-house experience in this area it means that you understand the importance of the architecture of Mobile eCommerce Platform.',
            rec: 'Recommendations:',
            list: [
                "Read more about the mobile architecture of IT eCommerce platforms in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got "Analysing and building the architecture of Mobile eCommerce Platform" competency covered by an external company.',
            body: 'It is great to hear that you have an external agency that supports you in analysing and building the architecture of Mobile eCommerce Platform.',
            rec: 'Recommendations:',
            list: [
                "Read more about the mobile architecture of IT eCommerce platforms in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your IT team lacks expertise in the field of "Analysing and building architecture of Mobile eCommerce platform"',
            body: 'Probably you are losing money because your Mobile eCommerce Platform architecture is not adjusted to mobile customers. Mobile is "a different animal" than desktop.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "You should consider hiring or outsource an IT specialist that has experience in 'Analysing and building architecture of Mobile eCommerce platforms'",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving IT team and competencies including the mobile architecture of IT eCommerce platforms.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "platform_implementation": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got experience in  "Mobile eCommerce platform implementations"',
            body: "The implementation phase is very important during the transformation from eCommerce to mCommerce (e2m transformation). It's great that you have experience in this area.",
            rec: 'Recommendations:',
            list: [
                'You should consider outsourcing an IT team that has great experience in "Mobile eCommerce platform implementations" to lower the risk of future projects.',
                "Read more about Mobile eCommerce platform implementation in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got "Mobile eCommerce platform implementation" competency covered by an external company.',
            body: "The implementation phase is very important during the transformation from eCommerce to mCommerce (e2m transformation). It's great that you have support from an external IT company in this area.",
            rec: 'Recommendations:',
            list: [
                "You should consider to find out the alternative to your actual external IT company to lower the risk in future projects and gain from a fresh point of view.",
                "Read more about Mobile eCommerce platform implementation in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your company and IT team lack expertise in the field of "Mobile eCommerce platform implementation"',
            body: 'Without experience in Mobile eCommerce platform implementation, you are risking your budget during e-shop transformation from eCommerce 2 Mobile eCommerce. Today on the market you can find people who will not learn from the mistakes made during the implementation for your company. Bet on experience.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                'You should consider hiring or outsource an IT team that has great experience in "Mobile eCommerce platform implementations".',
                'Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving IT team and competencies including "Mobile eCommerce platform implementation".',
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "mobile_testing": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got experience in "Testing of Mobile eCommerce platform"',
            body: "Testing is an important part of Mobile eCommerce Platform implementation. The bigger the e-shop, the bigger the testing team should be. It's great that you have such a team in-house. We strongly recommend considering finding an alternative to your internal team to lower the risk of future testing projects so you could focus on your core business, not testing challenges. ",
            rec: 'Recommendations:',
            list: [
                'You should consider or outsource an IT team that has great experience in "testing of Mobile eCommerce platforms".',
                "Read more about testing in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got "Testing of Mobile eCommerce platform" competency covered by an external company',
            body: "Development and implementation of Mobile eCommerce Platform is only the beginning.  It's great that you have got this area of expertise covered by an external agency. However, maybe it is worth having an alternative IT company to test your solutions faster?",
            rec: 'Recommendations:',
            list: [
                "You should consider to find out the alternative to your actual external IT company to lower the risk in future projects.",
                "Read more about testing in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your company and IT team lack expertise in the field of "testing of Mobile eCommerce platform"',
            body: 'Testing is a crucial part of Mobile eCommerce Platform implementation. The bigger the e-shop, the bigger the testing team should be.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                'You should consider hiring or outsource an IT team that has great experience in "testing of Mobile eCommerce platforms".',
                'Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving IT team and competencies including "testing of Mobile eCommerce platform".',
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "mobile_maintenance": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got experience in  "Maintenance of Mobile eCommerce platform"',
            body: 'Development and implementation of Mobile eCommerce Platform is only the beginning and it seems that you understand the need of having in-house a team that is responsible for maintenance of Mobile eCommerce platform. However, maybe it is wise to consider outsourcing those competencies to focus more on your core business, not IT challenges?',
            rec: 'Recommendations:',
            list: [
                'You should consider outsourcing an IT team that has great experience in "maintenance of Mobile eCommerce platforms".',
                "Read more about Maintenance of Mobile eCommerce platform in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got "Maintenance of Mobile eCommerce platform" competency covered by an external company.',
            body: "Development and implementation of Mobile eCommerce Platform is only the beginning.  It's great that you have got this area of expertise covered by an external agency. However, maybe it is worth having an alternative IT company to gain benefits from a fresh perspective?",
            rec: 'Recommendations:',
            list: [
                "You should consider to find out the alternative to your actual external IT company to lower the risk in future projects.",
                "Read more about Maintenance of Mobile eCommerce platform in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your company and IT team lack expertise in the field of "maintenance of Mobile eCommerce platform"',
            body: 'Development and implementation of Mobile eCommerce Platform is only the beginning. You should remember to include expenses related to the maintenance of the system in the annual budget.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                'You should consider hiring or outsource an IT team that has great experience in "maintenance of Mobile eCommerce platforms".',
                'Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving IT team and competencies including "maintenance of Mobile eCommerce platform".',
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "ux_interface": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got experience in  "Designing of  UX mobile interface"',
            body: 'UX mobile interface is a great challenge in the "mobile-first" world. It seems that your internal team covers this area of expertise. Our experience shows that it is wise to have an external IT company as an alternative to your internal team.',
            rec: 'Recommendations:',
            list: [
                'You should consider or outsource an IT team that has great experience in "maintenance of Mobile eCommerce platforms".',
                "Read more about designing of  UX mobile interface in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got "Maintenance of Mobile eCommerce platform" competency covered by an external company.',
            body: 'UX mobile interface is a great challenge in the "mobile-first" world. It seems that your external IT partner covers this area of expertise. Our experience shows that it is wise to have an alternative IT company to get out of the box ideas in UX of your e-shop.',
            rec: 'Recommendations:',
            list: [
                "You should consider to find out the alternative to your actual external IT company to lower the risk in future projects.",
                "Read more about designing of  UX mobile interface in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your company and IT team lack expertise in the field of "designing of  UX mobile interface"',
            body: "Your company is not ready for transformation from eCommerce to Mobile eCommerce. UX mobile interface is a great challenge in the 'mobile-first' world. Don't trust those experienced only in desktop UX.",
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "You should consider hiring or outsource a UX team that has great experience in 'designing of  UX mobile interface'.",
                "Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving UX team and competencies including 'designing of  UX mobile interface'.",
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "mobile_commerce": {
        "coveredI": {
            score: 'Covered - inhouse',
            head: 'Well done! Your company has got experience in  "Mobile eCommerce implementation project management"',
            body: 'Fast and hassle-free implementation is key to success in Mobile eCommerce implementations. Your company is aware of that and has experience in this area. However, maybe it is beneficial to have an external IT partner, who can bring the best and most efficient project management methods?',
            rec: 'Recommendations:',
            list: [
                'You should consider outsourcing an IT team that has great experience in "maintenance of Mobile eCommerce platforms".',
                "Read more about Mobile eCommerce project management methods in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "coveredO": {
            score: 'Covered - by external company',
            head: 'Well done! Your company has got "Mobile eCommerce implementation project management" competency covered by an external company.',
            body: 'Fast and hassle-free implementation is key to success in Mobile eCommerce implementations. Your company is aware of that and has external IT partner that covers this area. However, maybe it is beneficial to have an alternative to your IT partner to lower the risks in future projects and to know the best and most efficient project management methods?',
            rec: 'Recommendations:',
            list: [
                "You should consider to find out the alternative to your actual external IT company to lower the risk in future projects.",
                "Read more about Mobile eCommerce project management methods in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "not_covered": {
            score: 'Not covered',
            head: 'Warning! Your company lacks expertise in the field of "mobile eCommerce implementation project management"',
            body: 'Fast and hassle-free implementation is key to success in Mobile eCommerce implementations. Your company is not ready to run such projects at this moment. If you do so you are risking failed implementation and will require a "rescue mission" in a couple of months. ',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                'You should consider hiring or outsource an IT team that has great experience in "mobile eCommerce implementation project management".',
                'Visit our Mobile eCommerce Ultimate Guide where you will find a lot of insights about improving an IT team and competencies including "mobile eCommerce implementation project management".',
                "You can also contact our Mobile eCommerce Experts for support.",
            ]
        },
    },
    "keeping_information": {
        threshold: [1, 0.5],
        max_score: 10,
        "excellent": {
            score: 'Excellent',
            head: 'Well done! Your company is using PIM software to store information about products.',
            body: 'It is great to hear that your company is using PIM software. It means that you are aware of how important in Mobile eCommerce is dedicated software for specified processes (in this case product information management).',
            rec: 'We recommend:',
            list: [
                "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                "Read more about PIM software in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ],
        },
        "average": {
            score: 'Average',
            head: 'Requires attention! You could store product information in a better way.',
            body: 'ERP / CRM can be used to store product information but there is a better way to do that. PIM software (Product Information Management) can enable you to build B2B channels and connect to other eCommerce and Mobile eCommerce external platforms. ',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "PIM software implementation",
                "Read more about PIM software in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area."
            ],
        },
        "poor": {
            score: 'Poor',
            head: 'Warning! Your company is using excel files to store information about products.',
            body: 'It is a highly dangerous situation for the entire business to keep product information in excel files.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "Implement PIM software (Product Information Management)",
                "Read more about PIM software in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ],
        },
    },
    "transfer_information": {
        threshold: [1, 0.5],
        max_score: 10,
        "excellent": {
            score: 'Excellent',
            head: '"Well done! Your company is using PIM software to share information about products.',
            body: "It is great to hear that your company is using PIM software to share information about products. Your company is prepared to enter the next level of business development, cooperate with external eCommerce platforms and other B2B resellers. ",
            rec: 'We recommend:',
            list: [
                "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                "Read more about PIM software in our Mobile eCommerce Ultimate Guide.",
                'You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area."',
            ],
        },
        "average": {
            score: 'Average',
            head: 'Requires attention! You could share information about products in a more efficient way.',
            body: "ERP / CRM can be used to transfer product information across different departments but there is a more efficient way, that opens expansion potential for your eCommerce. PIM software can enable you to build B2B channels and connect to other eCommerce and Mobile eCommerce external platforms.",
            rec: "It is advised to take immediate action. We recommend:",
            list: [
                "PIM software implementation",
                "Read more about PIM software in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ],
        },
        "poor": {
            score: 'Poor',
            head: 'Warning! Your company is using excel files to share product information updates.',
            body: "It is a highly dangerous situation for the entire business to use excel files when sharing product information update. Probably your sales reps and external sales partners make a lot of mistakes when a customer is asking for a price or product availability. Your company is not prepared for sharing product information with other e-shop and eCommerce platforms (B2B eCommerce)",
            rec: "It is advised to take immediate action. We recommend:",
            list: [
                "PIM software implementation",
                "Read more about PIM software in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ],
        },
    },
    "digital_experience": {
        threshold: [1, 0.5],
        max_score: 10,
        "excellent": {
            score: 'Excellent',
            head: 'Well done! Your company uses DXP (Digital Experience Platform) to store information about the DX (Digital Experience) of your customers.',
            body: "It is great to hear that your company uses DXP. It means that are working in an innovative environment.",
            rec: "We recommend:",
            list: [
                "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                "Read more about DXM (Digital Experience Management) and DXP (Digital Experience Platform) in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ],
        },
        "average": {
            score: 'Average',
            head: 'Requires attention! Your company uses ERP/CRM to keep information about the DX (Digital Experience ) of your customers. ',
            body: 'ERP/CRM systems are not the best option for keeping and managing information about the DX (Digital Experience) of your customers.  You should consider implementing policies and processes about DXM (Digital Experience Management) and DXP (Digital Experience Platform).',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "Read more about DXM (Digital Experience Management) and DXP (Digital Experience Platform) in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ],
        },
        "poor": {
            score: 'Poor',
            head: 'Warning! Your company uses excel files to keep information about the DX (Digital Experience ) of your customers.',
            body: 'Keeping information about the Digital Experience of your customers in excel files is both dangerous and not efficent. You should consider implementing policies and processes about DXM (Digital Experience Management) and DXP (Digital Experience Platform).',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "Read more about DXM (Digital Experience Management) and DXP (Digital Experience Platform) in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ]
        },
        "none": {
            score: 'Poor',
            head: "Warning! You don't know where your company keeps the information about the DX (Digital Experience ) of your customers.",
            body: 'You should make internal research and find out how your company manages information about the Digital Experience (DX) of your customers. ',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "Read more about DXM (Digital Experience Management) and DXP (Digital Experience Platform) in our Mobile eCommerce Ultimate Guide.",
                "You can also contact our Mobile eCommerce Experts for support if you have any issues or challenges in this area.",
            ] 
        }
    },
    "technology": {
        threshold: [1, 0.66],
        max_score: 3,
        "excellent": {
            score: 'Excellent',
            head: 'Well done! The technology used is 100% fit to the scale and needs of your business.',
            body: 'It is great to hear that your company found a great technology that fits your needs and scale of the business.',
            rec: 'We recommend:',
            list: [
                "Tell the world about your success story. Contact our experts and become a part of our Mobile eCommerce Ultimate Guide. We will be delighted to write about it.",
                "Visit our Mobile eCommerce Ultimate Guide and read about choosing the technology according to the needs and scale of the business.",
                "You can also contact our Mobile eCommerce Experts for support."
            ],
        },
        "average": {
            score: 'Average',
            head: 'Warning! Your main page is not SEO friendly.',
            body: 'The platforms available on the market are dedicated to various needs and business scale. Your company seems to have a technology solution that can be improved.',
            rec: 'We recommend:',
            list: [
                "Contact our Mobile eCommerce Experts for further details and support. ",
                "Visit our Mobile eCommerce Ultimate Guide and read about choosing the technology according to the needs and scale of the business.",
            ],
        },
        "poor": {
            score: 'Poor',
            head: 'Warning! The technology used is not adequate to the scale and needs of your business.',
            body: 'The platforms available on the market are dedicated to various needs and business scale. Your company seems to have a technologically inappropriate solution.',
            rec: 'It is advised to take immediate action. We recommend:',
            list: [
                "Contact our Mobile eCommerce Experts for support.",
                "Visit our Mobile eCommerce Ultimate Guide and read about choosing the technology according to the needs and scale of the business.",
            ],
        },
    }
}

export default backendScoresTable