import React from 'react'

import RadarChart from 'react-svg-radar-chart'
import 'react-svg-radar-chart/build/css/index.css'


const Chart = ({ team, pim, digital_experience, analytics, technology }) => {

    const data = [
          {
            data: {
              technology: technology,
              analytics: analytics,
              digital_experience: digital_experience,
              pim: pim,
              team: team,
            },
            meta: { color: '#27ae60' }
          },
        ];
     
    const captions = {
          technology: 'Technology Matching',
          analytics: 'Analytics',
          digital_experience: 'Digital Experience',
          pim: 'PIM',
          team: 'Team',
        };

    return (
        <div>
            <RadarChart
                captions={captions}
                data={data}
                size={450}
            />
        </div>
    )
}

export default Chart
