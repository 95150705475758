import React from 'react'
import styles from './expandedResults.module.scss'

const Overlay = () => {
    return (
        <div className={styles.overlay}>
            <h2 className={`${styles.title} ${styles.textCenter}`}>Generating your report...</h2>
        </div>
    )
}

export default Overlay
